const Styles = theme => ({
    root: {
        position: 'relative',
        zIndex: '10',
        marginLeft: '40px'
    },
    pooper: {
        width: 'auto',
        minWidth: '200px',
        marginRight: '4px',
        '& .MuiPaper-root': {
            padding: '7px',
        }
    },
    secundaryButton: {
        margin: '7px 0px 0px 0px',
        height: '40px',
        color: 'red',
        '& .MuiButton-label':{
            color: 'rgba(0, 0, 0, 0.54);'
        }
    },
    menuItem: {
        color: '#474747 !important',
        borderRadius: "10px",
        margin: "4px 0px",
    },
    iconUser: {
        padding: 0,
        '& .MuiAvatar-root': {
            width: '24px',
            height: '24px',
            backgroundColor: '#000',
            '& svg': {
                width: '100%',
                height: '100%',
            }
        }
    }
})

export default Styles;