import Assets from "../../assets";


export const VIDEOWALL = {
    resolution: {
        width: 1248,
        height: 832 
    },
    displayCenter: true,
    logos: {
        top_left: {
            status: true,
            src: Assets.Images.vdf_logo
        },
        top_right: {
            status: true,
            src: Assets.Images.stage_cam_big
        },
        middle_bottom: {
            status: true,
            src: Assets.Images.vdf_poweredby
        },
    },
    background: {
        status: true,
        type: "IMAGE", // 'VIDEO' OR 'IMAGE'
        src: Assets.Images.background_vdf_coura_1248x832,
        color: "#000000"
    },
    audio: {
        status: true,
        src: Assets.Audios.all_i_need
    },
    qrcode: {
        width: 258,
        height: 258,
        position: {
            top: 441,
            left: 924
        },
        status: true,
        src: Assets.Images.videowall_qrcode
    },
    channels: {
        total: 7,
        width: 258,
        height: 258,
        positions: [
            { top: 134, left: 66 },
            { top: 155, left: 352 },
            { top: 134, left: 638 },
            { top: 155, left: 924 },
            { top: 420, left: 66 },
            { top: 441, left: 352 },
            { top: 420, left: 638 },
            { top: 441, left: 924 },
        ],
    },
    debug: false,
    queue: {
        total: 7
    },
    queueOffset: 520,
    request_interval: 1000, //miliseconds
    bo: {
        preview: {
            positions: [
                { top: 0, left: 0 },
                { top: 28, left: 230 },
                { top: 0, left: 460 },
                { top: 28, left: 690 },
                // { top: 0, left: 920 },
                // { top: 110, left: 1150 },
                { top: 230, left: 0 },
                { top: 258, left: 230 },
                { top: 230, left: 460 },
                { top: 258, left: 690 },
                // { top: 230, left: 920 },
                // { top: 340, left: 1150 },
            ]
        }
    }
};