import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Dialog, Slide, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import BackIcon from '@material-ui/icons/NavigateBefore';
import Pluscon from '@material-ui/icons/Add';
import MinimizeIcon from '@material-ui/icons/Minimize';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const FAQS = [
    { id: '1', question: 'Pergunta 1', answer: 'Resposta 1' },
    { id: '2', question: 'Pergunta 2', answer: 'Resposta 2'  },
    { id: '3', question: 'Pergunta 3', answer: 'Resposta 3' },
    { id: '4', question: 'Pergunta 4', answer: 'Resposta 4'  },

]


const Faqs = ({
    onBack
}) => {

    const classes = useStyles();

    const { t } = useTranslation();

    const [expanded, setExpanded] = useState(false);



    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <Dialog fullScreen open={true} TransitionComponent={Transition}>
            <div className={classes.root}>
                <div className={classes.header} onClick={() => onBack()}>
                    <BackIcon />
                    <span>{t('back')}</span>
                </div>
                <div className={classes.content}>
                    <h5>{t('videowall-app-faqs-title')}</h5>
                    {FAQS.map((it, index) => (
                        <Accordion key={`faq-${index}`} square={true} expanded={expanded === it.id} onChange={handleChange(it.id)} className={classes.accordion}>
                            <AccordionSummary
                                className={classes.accordionSummary}
                                expandIcon={expanded === it.id ? <MinimizeIcon /> : <Pluscon />}
                                aria-controls={`panel-${it.id}-content`}
                                id={`panel-${it.id}-header`}
                            >
                                {it.question}
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionDetail}>
                                { it.answer }
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>
        </Dialog>
    )

}

export default Faqs;


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#FFF",
        height: '100%',
        margin: "16px",
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            font: 'normal normal normal 14px/19px Segoe UI'
        }
    },
    content: {
        margin: '18px 16px 24px',
        '& h5': {
            font: 'normal normal normal 24px/32px Segoe UI',
            margin: '0 0 24px',
        },
        '& p': {
            font: 'normal normal normal 14px/19px Segoe UI',
            margin: 0
        }
    },
    accordion: {
        boxShadow: 'none',
        margin: 0,
        padding: 0,
        borderBottom: '1px solid #000',
        '&:before': {
            display: 'none'
        },
        '& .MuiCollapse-container': {
            width: '100%'
        }
    },
    accordionSummary: {
        padding: 0,
        width: '100%',
        font: 'normal normal bold 14px/19px Segoe UI',
        '& .MuiIconButton-root': {
            fontWeight: 'bold',
            color: '#000000'
        }
    },
    accordionDetail: {
        padding: 0,
        width: '100%',
        textAlign: 'left',
        marginBottom: '16px'
    }
}))
