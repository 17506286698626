import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, TextField, Button, CircularProgress, Typography } from '@material-ui/core';
import { EmailRounded} from '@material-ui/icons';

import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { AuthContext } from '../../../context/auth/auth-context.js';
import { LanguageSelector } from '../../../components';
import { useNotifications } from '../../../components/Shared/NotificationSnackbar';
import Assets from '../../../assets';
import Styles from './style';

const useStyles = makeStyles(Styles);

const RecoverPassword = () => {

    const { forgotPassword } = useContext(AuthContext);
    const { triggerNotification } = useNotifications();
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: async values => {
            try {
                const response = await forgotPassword(values.email);
                if(response){
                    triggerNotification("success", "notifications-recoverpassword-emailsent-message", "notifications-recoverpassword-emailsent-title");
                    navigate(`/auth/recover-password/confirm?user=${values.email}`);
                }
            } catch (error) {
                throw error;
            }
        },
    });

    return (
        <Container className={classes.main}>
            <Container className={classes.header}>
                   <div className={classes.logo}>
                    <img src={Assets.Images.cyc_logo_full} alt="logo" />
                </div>
                <LanguageSelector />
            </Container>
            <Container className={classes.content}>
                 <div className={classes.contentHeader}>
                    <div className={classes.titleBox}>
                        <Typography variant="h1">
                            Template
                        </Typography>
                    </div>
                  
                    <div className={classes.bulletBox}>
                        <img src={Assets.Images.cy_bullets} alt="bullets" />
                    </div>
                  
                </div>
                <Paper className={classes.paper} elevation={0}>
                    <div className={classes.title}>{t('recoverpass-text-title')}</div>
                    <div className={classes.subtitle}>{t('recoverpass-text-subtitle')}</div>
                    <br />
                    <form onSubmit={formik.handleSubmit} className={classes.form}>
                        <TextField
                            id="email"
                            type='email'
                            placeholder={t('recoverpass-input-email')}
                            variant="outlined"
                            InputProps={{ startAdornment: <EmailRounded className={classes.icons} /> }}
                            onChange={formik.handleChange}
                            required
                        />
                        <Button type='submit' variant="contained" disableElevation disabled={formik.isSubmitting}>{
                            formik.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('recoverpass-btn-text')
                        }</Button>
                    </form>
                      <span className={classes.subtext}>{t('recoverpass-text-subtext-1')}&nbsp;&nbsp;
                        <a onClick={() => navigate('/auth/sign-in')} className={classes.anchor}>{t('recoverpass-text-subtext-2')}</a></span>
                </Paper>
            </Container>
              <Container className={classes.footer} maxWidth="xl">
                <span className={classes.cycText}>CYCLOID Technology and Consulting, Lda.</span>
                <span className={classes.cycPrivacy}>© All rights reserved.&nbsp;<a href="https://www.cycloid.pt/pdf/Cycloid_Privacy%20Policy.pdf" className={classes.anchor} target="_blank">{t('signin-footer-text-subtext-2')}</a></span>
                <span className={classes.cycWebsite}>www.cycloid.pt</span>
            </Container>
        </Container>
    );
}

export default RecoverPassword;













// const multiStyle = (...args) => {
//     let styles = {};
//     for (var i = 0; i < args.length; {
//         styles = { ...styles, ...args[i] };
//     }
//     console.log(args);
//     return styles;
// }

// const multiClassNames = (...args) => {
//     let classes = "";
//     for (var i = 0; i < args.length; {
//         classes += args[i] + " "
//     }
//     console.log(classes);
//     return classes;
// }
