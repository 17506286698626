import Image from "../../../assets/images/cycloid_pattern.png";
import RIR from "../../../assets/images/rir_background.png";

const Styles = theme => ({
    root: {
        display: 'flex',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#000000'
    },
    body: {
        width: "1920px",
        height: "1080px",
        display: "flex",
        position: 'relative',
        overflow: 'hidden'
    },
    feedsContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        position: 'absolute',
        zIndex: '10',
    },
    backgroundCover: {
        display: 'flex',
        // backgroundImage: `url(${RIR})`,
        // backgroundRepeat: 'repeat',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    },
    overlay: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        color: "#FFFFFF",
        fontSize: "30px",
        fontWeight: 'bold',
        zIndex: '300',
    },
    watermark: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
        borderRadius: '10px',
        position: 'absolute',
        bottom: '15px',
        right: '15px',
        // backgroundColor: '#ffffff',
        zIndex: '99999999',
        '& img': {
            width: '15px',
            height: '15px',
            marginRight: '5px'
        },
        '& p': {
            margin: 0,
            padding: 0,
            color: 'rgba(0,0,0,0.4)'
        },
    },
    connectionPoint: {
        width: "7px",
        height: "7px",
        position: 'absolute',
        bottom: 120,
        right: 45,
        borderRadius: 35,
        backgroundColor: '#FFFFFF',
        zIndex: 999999,
    }
})

export default Styles;