import React, { useContext } from 'react';

//Router
import { Routes, Route, Navigate } from 'react-router-dom';

import { AuthContext } from '../context/auth/auth-context';

//Layout
import PrivateLayoutBo from '../layout/Bo/private-layout-bo';
import PrivateLayoutApp from '../layout/App/private-layout-app';

//Screens
import OAuthRedirector from '../screens/OAuthRedirector';


import DashboardBo from '../screens/Backoffice/Dashboard';

import VideoStreamChannelView from '../screens/Backoffice/VideoStream/Channels';
import VideoStreamConfigView from '../screens/Backoffice/VideoStream/Configuarations';


import SignIn from '../screens/Auth/SignIn';
import RecoverPassword from '../screens/Auth/RecoverPassword';
import NewPasswordRequired from '../screens/Auth/NewPasswordRequired';
import RecoverPasswordConfirm from '../screens/Auth/RecoverPasswordConfirm';

// VIDEO-WALL
import ViewerView from '../screens/VideoWall/Viewer';
import MasterView from '../screens/VideoWall/Master';
import MasterViewNextEvent from '../screens/VideoWall/Master/NextEvent';
import MasterViewCurrentEvent from '../screens/VideoWall/Master/CurrentEvent';
import ScheduleUpdateView from '../screens/Backoffice/VideoStream/Configuarations/Views/Update';

import LayoutVideoWallMaster from '../layout/VideowallMaster';


import { VideowallProvider } from '../context/Videowall';



const AllRoutes = () => {

    const { isLoading } = useContext(AuthContext)

    if (isLoading) return null

    return (
        <Routes>

            <Route path="/">

                <Route path='/' element={
                    <VideowallProvider>
                        <LayoutVideoWallMaster>
                            <MasterViewCurrentEvent />
                        </LayoutVideoWallMaster>
                    </VideowallProvider>
                } />

                <Route path='/next-event' element={
                    <VideowallProvider>
                        <LayoutVideoWallMaster>
                            <MasterViewNextEvent />
                        </LayoutVideoWallMaster>
                    </VideowallProvider>
                } />




                <Route path='*' element={<Navigate to="/next-event" />} />
            </Route>



            <Route path="videowall">
                <Route path="/videowall" element={
                    <RequireAuthVideoWall>
                        <ViewerView />
                    </RequireAuthVideoWall>
                } />
                <Route path="auth/sign-in" element={<SignIn />} />
                <Route path="auth/recover-password" element={<RecoverPassword />} />
                <Route path="auth/recover-password/confirm" element={<RecoverPasswordConfirm />} />
                <Route path="auth/new-password-required" element={<NewPasswordRequired />} />
                <Route path='*' element={<Navigate to="videowall" />} />
            </Route>

            <Route path="bo">
                <Route path='/bo' element={<Navigate to="/bo/video-stream/channels" />} />
                <Route path="video-stream" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <DashboardBo />
                        </PrivateLayoutBo>
                    </RequireAuthBo>

                } />

                <Route path="video-stream/channels" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <VideoStreamChannelView />
                        </PrivateLayoutBo>
                    </RequireAuthBo>

                } />

                <Route path="video-stream/schedule" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <VideoStreamConfigView />
                        </PrivateLayoutBo>
                    </RequireAuthBo>

                } />

                <Route path="video-stream/schedule/:id" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <ScheduleUpdateView />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />


                {/* <Route path="dashboard" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <DashboardBo />
                        </PrivateLayoutBo>
                    </RequireAuthBo>

                } />

                <Route path="groups" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <Groups />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="groups/create" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <CreateGroup />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="groups/edit/:slug" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <EditGroup />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />


                <Route path="users" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <Users />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="users/create" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <CreateUser />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="users/edit/:slug" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <EditUser />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="todos/*" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <Todos />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="form/*" element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <Form />
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } /> */}


                <Route path='*' element={
                    <RequireAuthBo>
                        <PrivateLayoutBo>
                            <p>PAGE BO NO FOUND</p>
                        </PrivateLayoutBo>
                    </RequireAuthBo>
                } />

                <Route path="auth/sign-in" element={<SignIn />} />
                <Route path="auth/recover-password" element={<RecoverPassword />} />
                <Route path="auth/recover-password/confirm" element={<RecoverPasswordConfirm />} />
                <Route path="auth/new-password-required" element={<NewPasswordRequired />} />
                <Route path='*' element={<p>PAGE BO NO FOUND</p>} />
            </Route>

            <Route path="oauth-redirector" element={<OAuthRedirector />} />

            <Route path='*' element={<p>NO FOUND</p>} />
        </Routes>
    )


}


const RequireAuthBo = ({ children }) => {


    const { isAuthenticated, groups, roleBo } = useContext(AuthContext)

    const isIncluded = roleBo?.some(value => groups?.includes(value)) || roleBo?.includes('*')

    if (!isAuthenticated || !isIncluded) {
        return <Navigate to="/bo/auth/sign-in" state={{ from: '/bo/video-stream/channels' }} replace />;
    }
    return children;
}

const RequireAuthVideoWall = ({ children }) => {
    const { isAuthenticated, groups, roleBo } = useContext(AuthContext)
    // const isIncluded = roleBo?.some(value => groups?.includes(value)) || roleBo?.includes('*')
    if (!isAuthenticated /*|| !isIncluded*/) {
        return <Navigate to="/videowall/auth/sign-in" state={{ from: '/videowall' }} replace />;
    }
    return children;
}


const RequireAuthApp = ({ children }) => {

    const { groups, roleApp, signout, isAuthenticated } = useContext(AuthContext)

    const isIncluded = roleApp?.some(value => groups?.includes(value)) || roleApp?.includes('*')

    if (!isIncluded && isAuthenticated) {
        signout()
    }
    return children;
}




export default AllRoutes;