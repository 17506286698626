
import Assets from '../../assets'


const Styles = theme => ({
    root: {
        height: '100vh',
        width: '100vw',
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        background: Assets.Images.background,
        backgroundImage: `url(${Assets.Images.videowall_background})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        flexDirection: 'column',
        position: 'fixed',
        overflowY: 'auto'
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    top: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '13px 18px',
        alignItems: 'center',
        width: '288px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    titleBox: {
        display: 'flex',
        marginBottom: 16,
        justifyContent: 'center',
        color: '#FFF'
    }
})

export default Styles;
