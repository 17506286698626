import React from 'react';
import { useTranslation } from 'react-i18next';

//Material
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress } from "@material-ui/core";




const Loading = () => {

    const { t } = useTranslation();

    const classes = useStyles();


    return (

        <Box className={classes.root}>
            <CircularProgress  thickness={1}/>
            {/*<h4>{t('loading')}...</h4>*/}
        </Box>
    )
}

export default Loading;



const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height:'100vh',
        '& .MuiCircularProgress-root':{
            width: '100px !important',
            height: '100px !important',
            color: 'rgba(240, 240, 240, 0.5)'
        }
    }
});
