import React, { createContext, useState, useContext, useEffect } from 'react';
import  {  Hub } from 'aws-amplify';


const OAuthContext = createContext()

const OauthProvider = ({ children }) => {

    const [oAuthLoading, setOAuthLoading] = useState(true)
    const [oAuthRerirect, setOAuthRedirect] = useState(null)


    useEffect(() => {
        const hubListener = () => {
            Hub.listen("auth", ({ payload: { event, data } }) => {
                switch (event) {
                    case "signIn":
                        console.log("signed in", data)
                        setOAuthRedirect(data)
                        break;
                    case "signOut":
                        console.log("signed out", data)
                        setOAuthRedirect(data)
                        break;
                    case "customOAuthState":
                        console.log("customOAuthState", data)
                        setOAuthRedirect(data)
                        break;
                    default: break;
                }
            });
        }
        console.log('OAuthContext')
        hubListener()

        return () => {
            Hub.remove('auth')
        }
    }, []);



   


   

    return (
        <OAuthContext.Provider
            value={{
                oAuthLoading, setOAuthLoading,
                oAuthRerirect, setOAuthRedirect

            }}>
            {children}
        </OAuthContext.Provider>
    );

}

function useOAuth() {
    const context = useContext(OAuthContext);
    if (!context) {
        throw new Error('useOAuth must be used within an OauthProvider.');
    }
    return context;
}


export { OauthProvider, useOAuth };
