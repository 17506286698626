const Styles = theme => ({
    root: {
        width: '80%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        width: '100%',
        display: 'flex',
        padding: '10px 0px ',
        ...theme.typography.title_h1_reg,
    },
    subtitle: {
        width: '100%',
        display: 'flex',
        padding: '20px 0px ',
        ...theme.typography.title_h1_reg,
        fontSize: 30
    },
    header: {
        display: 'flex',
        flex: 1,
        padding: "10px 0px",
        margin: "20px 0px",
        position: "relative",
        // backgroundColor: '#F2F3F4'
    },
    cell: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    column: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    body: {
        display: 'flex',
        flex: 1,
        // padding: "20px",
        position: "relative",
        flexDirection: "column"
        // height: "calc(100vh-100px)",
    },
    feedsContainer: {
        display: "flex",
        position: "relative",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        zIndex: '10',
    },
    overlay: {
        display: "flex",
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        color: "#FFFFFF",
        fontSize: "30px",
        fontWeight: 'bold',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: '300',
    },
    queueContainer: {
        display: "flex",
        position: "relative",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        zIndex: '10',
        padding: "10px 0px 20px 0px"
    },
    queueBoxList: {
        display: "flex",
        flexWrap: 'wrap'
    }
})

export default Styles;