import React from 'react';
import { makeStyles, Dialog } from '@material-ui/core';
import Button from '../Button';
import  Assets  from '../../../assets'


const LANGS = [
    { id: 'en', name: 'English' },
    { id: 'pt', name: 'Português' }
]


const LanguageOptions = ({
    onSelect
}) => {

    const classes = useStyles();

    const handleSelect = (id) => {
        onSelect(id)
    }


    return (
        <Dialog fullScreen open={true} className={classes.dialog} /*TransitionComponent={Transition}*/>
            <div className={classes.root}>
                <div className={classes.logoStart}>
                    <img src={Assets.Images.videowall_stage_cam_start_all} alt=""/>
                </div>
                <div className={classes.buttonBox}>
                    {LANGS.map(it => (
                        <Button key={it.id} label={it.name} onClick={() => handleSelect(it.id)} styles={{ backgroundColor: '#ffffff', color: '#e30613'}} />
                    ))}
                </div>
                <div className={classes.footerBox}>
                    <span>© Vodafone {new Date().getFullYear()}</span>
                </div>
            </div>
        </Dialog>
    )

}

export default LanguageOptions;


const useStyles = makeStyles(theme => ({
    dialog: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        backgroundImage: `url(${Assets.Images.videowall_background})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0) !important'
        }
    },
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        width: "100%",
        height: '100%'
    },
    buttonBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '32px',
        marginTop: '20px'
    },
    logoStart: {
       paddingTop: '34px',
       width: '272px',
       '& img': {
          width: '100%',
       }
    },
    titleBox: {
        fontSize: '36px',
        color: 'white',
        textAlign: 'center',
        margin: '22px auto 22px'
    },
    poweredBox: {

    },
    footerBox: {
        '& span': {
            font: 'normal normal normal 11px VodafoneRg',
            margin: 0,
            color: '#FFF',
            paddingBottom: '12px'
        }
    }
}))
