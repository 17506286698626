import React, { useEffect, useState, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import Footer from '../../../../components/VideoWall/Footer';
import { useVideowall } from '../../../../context/Videowall';
import Toogle from '../../../../components/VideoWall/Toogle';
import { ServiceContext } from '../../../../context/services/service-context';
import { AuthContext } from '../../../../context/auth/auth-context';
import { startMaster, stopMaster } from '../master';
import InfoPopup from '../../../../components/VideoWall/InfoPopup';
import LoadingPopup from '../../../../components/Shared/LoadingPopup';
import Assets from '../../../../assets';



const CurrentEvent = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const videoRef = useRef(null)
    const { guid, showCam, checkLocation, permissionCamara, requestCamPermissions, permissionCamaraRequested, updateChannelStatus } = useVideowall()
    const { VideoStream } = useContext(ServiceContext);
    const { signin, currentUserSession, currentUserCredentials } = useContext(AuthContext);
    const [loading, setLoading] = useState(true)
    const [live, setLive] = useState(false)
    const [noChannel, setNoChannel] = useState(false)
    const [camCanPlay, setCamCanPlay] = useState(false)
    const [currentChannel, setCurrentChannel] = useState(null)
    const [loadingChannel, setLoadingChannel] = useState(false)
    const [outsideLocation, setOutsideLocation] = useState(false)
    const [connected, setConnected] = useState(false)
    const [connectionEnded, setConnectionEnded] = useState(false)




    const streammingHandler = async (on) => {
        if (loadingChannel) return
        if (on) {
            if (live) return
            setLoadingChannel(true)
            onStartHandler(videoRef)
        } else {
            onStopHandler()
        }
    }

    // Get anonymous user
    const authAnonymousUser = async () => {
        try {
            let haveSession = await currentUserSession();
            if (!haveSession) {
                let { data } = await VideoStream.getAnonymousUser();
                await signin(data.getAnonymousUser.username, data.getAnonymousUser.password);
            }
        } catch (error) {
            throw error
        }
    }

    // Stop streaming
    const onStopHandler = () => {
        stopMaster()
        setCam()
    }

    // Start streaming
    const onStartHandler = async (videoRef) => {
        let data = {
            ...VALUES1,
        }
        try {
            const session = await currentUserSession()
            let type = 'AMAZON_COGNITO_USER_POOLS'
            if (!session) type = 'AWS_IAM'

            let _channel = await VideoStream.getChannel(type, guid);

            if (!_channel) throw new Error('no channel')

            await authAnonymousUser();

            let credentials = await currentUserCredentials();

            data = {
                ...data,
                channelName: _channel?.name,
                videoRef,
                accessKeyId: credentials.accessKeyId,
                secretAccessKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken,
            }

            setCurrentChannel(_channel)

            startMaster(data, () => { }, () => { }, () => {
                //on peer connect
                //console.log("you got in, congrats!!!");
                setConnected(true)
            }, () => {
                //on peer disconnect
                setLoadingChannel(true)
                setLive(false)
                setLoadingChannel(false)
                setConnected(false)
                setConnectionEnded(true)
                onStopHandler();
            }).then(master => {
                // Connect open
                master.signalingClient.on('open', async () => {
                    setLive(true)
                    setLoadingChannel(false)
                    updateChannelStatus(_channel, "STARTED")
                });
                // Stop
                master.signalingClient.on('close', () => {
                    setLoadingChannel(true)
                    updateChannelStatus(_channel, "AVAILABLE")
                    setLive(false)
                    setLoadingChannel(false)
                });

            }).catch((error) => {
                setLive(false)
                updateChannelStatus(_channel, "AVAILABLE")
                setLoadingChannel(false)
            })

        } catch (error) {
            setLoadingChannel(false)
            setNoChannel(true)
            setLive(false)
            setCam()
        }
    }

    const setCam = async () => {
        const stream = await showCam()
        if (stream) {
            const video = videoRef.current
            if (video) {
                video.srcObject = stream
                video.onloadedmetadata = function (e) {
                    video.play()
                }
            }
        }
        setLoading(false)
    }


    useEffect(() => {
        if (!outsideLocation) {
            setCam()
            document.addEventListener("visibilitychange", async function () {
                if (document.visibilityState === 'hidden') {
                    onStopHandler()
                    if (currentChannel) {
                        updateChannelStatus(currentChannel)
                    }
                }
                setLive(false)
            });

            window.addEventListener("pagehide", event => {
                onStopHandler()
                if (currentChannel) {
                    updateChannelStatus(currentChannel)
                }
                setLive(false)
            }, false);

            window.addEventListener("onblur", () => {
                onStopHandler()
                if (currentChannel) {
                    updateChannelStatus(currentChannel)
                }
                setLive(false)
            })
        } else {
            onStopHandler()
        }

        return () => {
            if (currentChannel) {
                updateChannelStatus(currentChannel)
                onStopHandler()
            }
        }
    }, [outsideLocation])



    useEffect(() => {
        setLoading(true)
        let intervalId = null;

        const checkEventLocation = async () => {
            intervalId = setInterval(async () => {
                navigator.geolocation.getCurrentPosition(async (position) => {
                    const { latitude, longitude, accuracy } = position.coords
                    const result = await checkLocation(latitude, longitude, accuracy)
                    setOutsideLocation(!result)
                }, (error) => {
                    setOutsideLocation(true)
                }, { enableHighAccuracy: true });
            }, 5000);
        }
        const getCurrentPosition = async () => {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const { latitude, longitude, accuracy } = position.coords
                const result = await checkLocation(latitude, longitude, accuracy)
                setOutsideLocation(!result)
            }, (error) => {
                setOutsideLocation(true)
            }, { enableHighAccuracy: true });
        }

        getCurrentPosition()
        setLoading(false)
        checkEventLocation()
        return () => {
            clearInterval(intervalId);
        };
    }, [])


    useEffect(() => {
        let timer;
        if (connectionEnded) {
            onStopHandler();
            timer = setTimeout(() => {
                setConnectionEnded(false)
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [connectionEnded]);




    return (
        <>
            <div className={classes.root}>
                <div className={classes.header}>
                    <h3>{t('videowall-app-current-event-title')}</h3>
                    {!outsideLocation && permissionCamara && (
                        <h4>{!live ? t('videowall-app-current-event-info-go-live') : t('videowall-app-current-event-info-top-toogle-on')}</h4>
                    )}
                </div>

                {outsideLocation && !loading ? (
                    <div className={classes.contentLocationError}>
                        <div>
                            <img src={Assets.Images.videowall_ico_location} alt="" />
                        </div>
                        <div className={classes.locationInfoBox}>
                            <span>{t('videowall-app-current-event-location-error')}</span>
                        </div>
                    </div>
                ) : (
                    !outsideLocation && !permissionCamara && !loading && permissionCamaraRequested ? (
                        <div className={classes.contentCamError} onClick={() => {
                            requestCamPermissions()
                            setCam()
                        }}>
                            <div>
                                <img src={Assets.Images.videowall_ico_camera} alt="" />
                            </div>
                            <div className={classes.camInfoBox}>
                                <span>{t('videowall-app-cam-error')}</span>
                                <div>
                                    <img src={Assets.Images.videowall_ico_refresh} alt="" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={classes.content}>
                            <video ref={videoRef} id="video-stream" style={{ transform: 'scale(-1, 1)' }} autoPlay muted playsInline onCanPlay={() => setCamCanPlay(true)} />
                            {permissionCamara && !loading && (
                                <div className={classes.toogleBox} style={{ opacity: (noChannel && !loading) ? 0.3 : 1 }}>
                                    <span >{live ? t('videowall-app-current-event-info-on-live') : t('videowall-app-current-event-info-top-toogle-off')}</span>
                                    <Toogle on={live} onChange={status => streammingHandler(status)} disabled={loadingChannel} />
                                </div>
                            )}
                            {connected && live && (
                                <div className={classes.gradCamConnect}>
                                    {t('videowall-app-on-screen')}
                                </div>
                            )}

                            {connectionEnded && (
                                <div className={classes.gradCamDisconnect}>
                                    {t('videowall-app-left-screen')}
                                </div>
                            )}

                            {noChannel && !loading && (
                                <div className={classes.errorBox}>
                                    <InfoPopup
                                        info={t('videowall-app-channel-error')}
                                        onRefresh={() => {
                                            setNoChannel(false)
                                        }} />
                                </div>
                            )}
                            {camCanPlay && (
                                <>
                                    {/*<div className={classes.overlayTop} />*/}
                                    <div className={classes.overlayBottom} />
                                </>
                            )}
                        </div>
                    )
                )}
                <Footer />
            </div>
            {loadingChannel && <LoadingPopup />}
        </>
    )
}

export default CurrentEvent


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: '0px',
        flexDirection: 'column'
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '90px',
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4))',
        '& h3': {
            color: '#FFF',
            margin: 0,
            font: 'normal normal bold 32px/21px VodafoneRg',
            paddingBottom: '12px'
        },
        '& span': {
            cursor: 'pointer',
            color: '#FFF',
            margin: '0 10px',
            font: 'normal normal normal 14px/12px VodafoneRg',
            textDecoration: 'underline'
        },
        '& h4': {
            font: 'normal normal bold 16px/19px VodafoneRg',
            color: '#FFFFFF',
            paddingTop: '10px',
            textAlign: 'center',
            whiteSpace: 'pre-line',
            maxWidth: '320px',
            margin: 0
        }
    },
    content: {
        position: 'relative',
        height: '320px',
        background: '0% 0% no-repeat padding-box',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        margin: '0px 0px 14px',
        '& video': {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    },
    footer: {
        display: 'flex',
        flexDirection: 'column'
    },
    row: {
        display: 'flex',
    },
    toogleBox: {
        position: 'absolute',
        bottom: 10,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 200,
        '& span': {
            font: 'normal normal normal 16px/19px VodafoneRg',
            color: '#FFFFFF',
            maxWidth: '260px',
            textAlign: 'center',
            whiteSpace: 'pre-line'
        }
    },
    errorBox: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 300,
    },
    infoBox: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        zIndex: 12,
        '& span': {
            font: 'normal normal normal 14px/19px VodafoneRg',
            color: '#FFFFFF',
            paddingTop: '10px',
            textAlign: 'center',
            whiteSpace: 'pre-line',
            maxWidth: '260px'
        }
    },
    test: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'transparent'
    },
    overlayTop: {
        height: '80px',
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0))',
        position: 'absolute',
        top: 0,
        width: '100%',
        zIndex: 10
    },
    overlayBottom: {
        height: '120px',
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.85))',
        position: 'absolute',
        bottom: 0,
        width: '100%',
        zIndex: 10
    },
    contentCamError: {
        position: 'relative',
        height: '320px',
        background: '0% 0% no-repeat padding-box',
        backgroundColor: 'rgba(227, 6, 19, 0.85)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '57px',
        marginBottom: '14px'
    },
    camInfoBox: {
        marginTop: '21px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '276px',
        '& span': {
            font: 'normal normal normal 18px/20px VodafoneRg',
            textAlign: 'center',
            color: '#FFF',
            paddingBottom: '17px',
            whiteSpace: 'pre-line'
        }
    },
    contentLocationError: {
        position: 'relative',
        height: '320px',
        background: '0% 0% no-repeat padding-box',
        backgroundColor: 'rgba(227, 6, 19, 0.85)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '57px',
        marginBottom: '14px'
    },
    locationInfoBox: {
        marginTop: '21px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '276px',
        '& span': {
            font: 'normal normal normal 18px/20px VodafoneRg',
            textAlign: 'center',
            color: '#FFF',
            paddingBottom: '17px',
            whiteSpace: 'pre-line'
        }
    },
    gradCamConnect: {
        position: 'absolute',
        top: 20,
        width: '280px',
        height: '40px',
        padding: '6px 12px 7px',
        borderRadius: '40px',
        backgroundColor: 'rgba(227, 6, 19, 0.85)',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
        fontFamily: 'VodafoneRg',
        fontSize: '24px',
        color: '#fff',
        lineHeight: 0.88,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        animation: "1s ease infinite $pulse",
    },
    gradCamDisconnect: {
        position: 'absolute',
        top: 20,
        width: '280px',
        height: '40px',
        padding: '6px 12px 7px',
        borderRadius: '40px',
        backgroundColor: '#FFFFFF',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
        fontFamily: 'VodafoneRg',
        fontSize: '24px',
        color: '#e30613',
        lineHeight: 0.88,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    "@keyframes  pulse": {
        "0%": {
            backgroundColor: 'rgba(227, 6, 19, 0.85)',
        },
        "50%": {
            backgroundColor: 'rgba(227, 6, 19, 0.55)',
        },
        "100%": {
            backgroundColor: 'rgba(227, 6, 19, 0.85)',
        },
    },



}))



const VALUES1 = {
    region: "eu-west-1",
    channelName: "signalingChannel1651589896146",
    clientId: 'RANDOM_VALUE1',
    sendVideo: true,
    sendAudio: false,
    openDataChannel: false,
    widescreen: false,
    fullscreen: true,
    useTrickleICE: true,
    natTraversalDisabled: false,
    forceTURN: false,
    accessKeyId: 'AKIAVA4HVHYE52XFU754',
    endpoint: null,
    secretAccessKey: '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',
    sessionToken: null,
};
