import { useNotifications } from '../../../components/Shared/NotificationSnackbar';
import { ListGroups, CreateGroup, ListUsers, CreateUser, GetUser, UpdateUser, RemoveUserFromGroup } from '../../../service/api';

export const GroupAndUserService = () => {

    const { triggerNotification } = useNotifications();


    const listGroup = async () => {
        try {
            const result = await ListGroups();
            return result
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                // Filter your errors, and use the notification component to prompt the message
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    const createGroup = async (data) => {
        try {
            const result = await CreateGroup(data);
            return result
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                // Filter your errors, and use the notification component to prompt the message
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    const listUser = async () => {
        try {
            const result = await ListUsers();
            return result
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                // Filter your errors, and use the notification component to prompt the message
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    const createUser = async (data) => {
        try {
            const result = await CreateUser(data);
            return result
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                // Filter your errors, and use the notification component to prompt the message
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    const updateUser = async (data) => {
        try {
            const result = await UpdateUser(data);
            return result
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                // Filter your errors, and use the notification component to prompt the message
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    const removeUserFromGroup = async (data) => {
        try {
            const result = await RemoveUserFromGroup(data);
            return result
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                // Filter your errors, and use the notification component to prompt the message
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }



    const getUser = async (data) => {
        try {
            const result = await GetUser(data);
            return result
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                // Filter your errors, and use the notification component to prompt the message
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }









    return { listGroup, createGroup, listUser, createUser, getUser, updateUser, removeUserFromGroup }
}