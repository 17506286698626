import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ServiceContext } from '../../../context/services/service-context';
import { AuthContext } from '../../../context/auth/auth-context';
import { useVideowall } from '../../../context/Videowall';
import { startMaster, stopMaster } from './master';
import Styles from './style';

const useStyles = makeStyles(Styles);

const MasterView = () => {

    const classes = useStyles();
    const { VideoStream } = useContext(ServiceContext);
    const { requestCamPermissions } = useVideowall()
    const { signin, currentUserSession } = useContext(AuthContext);
    const [connected, setConnected] = useState(false)
    const [channel, setChannel] = useState(null)
    const [status, setStatus] = useState('INACTIVE')
    const [onDate, setOnDate] = useState(false)


    const checkEvent = async () => {
        console.log('checkEvent')
        const resp = await VideoStream.getCalendarEvent()
        const { events } = resp;

        const currentTimestamp = Date.now()

        events.forEach(el => {
            if (currentTimestamp >= el.startTime && currentTimestamp <= el.endTime) {
                console.log(el.startTime, el.endTime)
                setOnDate(true)
                return null
            }
        });
        setOnDate(true)
        requestCamPermissions()
        return null
    }


    // Get event calendar 
    const fetchConfiEvent = async () => {
        const resp = await VideoStream.getCalendarEvent()
        console.log(resp)
        // TO DO - > Check time
    }

    // Get event status
    const getEventStatus = async () => {
        const status = await VideoStream.getEventStatus()
        setStatus(status)
    }


    // Set status channel
    const setStatusChannel = async (channel) => {
        const data = {
            name: channel?.name,
            status: 'AVAILABLE'
        }
        await VideoStream.updateChannelStatus(data)
        setConnected(false)
    }


    // Get anonymous user
    const authAnonymousUser = async () => {
        try {
            let haveSession = await currentUserSession();
            //let haveCredentials = await currentUserCredentials();
            if (!haveSession) {
                let { data } = await VideoStream.getAnonymousUser();
                await signin(data.getAnonymousUser.username, data.getAnonymousUser.password);
                //haveCredentials = await currentUserCredentials();
            }
            //setCredentials(haveCredentials);
        } catch (error) {
            throw error
        }
    }

    // Stop streaming
    const onStopHandler = () => {
        stopMaster()
    }

    // Start streaming
    const onStartHandler = async () => {
        let data = {
            ...VALUES1,
        }
        try {
            let _channel = await VideoStream.getChannel();

            data = {
                ...data,
                channelName: _channel?.name
            }

            if (!_channel) throw new Error('no channel')

            authAnonymousUser();

            startMaster(data, () => { }, () => { }).then(master => {
                // Connect open
                master.signalingClient.on('open', async () => {
                    console.log('******* Connected to signaling service ********');
                    setConnected(true)
                });
                // Stop
                master.signalingClient.on('close', () => {
                    console.log('[MASTER] Disconnected from signaling channel');
                    setStatusChannel(_channel)
                });
            }).catch(() => {
                setConnected(false)
            })
            setChannel(_channel)

        } catch (error) {
            // Handler error
        }
    }


    useEffect(() => {

        if (!onDate) return null

        getEventStatus()

        document.addEventListener("visibilitychange", async function () {
            if (document.visibilityState === 'hidden') {
                console.log(document.visibilityState)
                setStatusChannel(channel)
            }
        });

        window.addEventListener("pagehide", event => {
            console.log(event)
            setStatusChannel(channel)
        }, false);
    }, [onDate])


    useEffect(() => {
        checkEvent()
    }, [])


    /*
    useEffect(() => {
        const intervalId = setInterval( async () => {
            checkEvent()
        }, 5000);
        return () => clearInterval(intervalId);
    }, []);
    */


    //if (!channel) return <UnvailableChannel />

    if (!onDate) return (
        <div style={{ display: 'flex' }}>
            <div>Out of Date</div>
            <button onClick={() => checkEvent()}>
                Refresh
            </button>
        </div>
    )

    if (status === 'INACTIVE') return <div>INATIVO</div>

    return (
        <div className={classes.root}>
            <video id="videoLocal" className={classes.feed} autoPlay={true}></video>
            <div className={classes.footer}>
                {connected ? (
                    <div onClick={() => onStopHandler()} className={classes.button}>
                        STOP
                    </div>
                ) : (
                    <div onClick={() => onStartHandler()} className={classes.button}>
                        START
                    </div>
                )}
            </div>
        </div>
    );
}

export default MasterView;


const VALUES1 = {
    region: "eu-west-1",
    channelName: "signalingChannel1651589896146",
    clientId: 'RANDOM_VALUE1',
    sendVideo: true,
    sendAudio: false,
    openDataChannel: false,
    widescreen: false,
    fullscreen: true,
    useTrickleICE: true,
    natTraversalDisabled: false,
    forceTURN: false,
    accessKeyId: 'AKIAVA4HVHYE52XFU754',
    endpoint: null,
    secretAccessKey: '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',
    sessionToken: null,
};