const Styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: '50px 0px'
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    columnContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.between('0', '600')]: {
            flexDirection: 'column',
        },
    },
    column: {
        flex: '1',
        minWidth: '300px',
        [theme.breakpoints.between('0', '600')]: {
            minWidth: 'auto',
        },
        padding: '0px 7px',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '15px'
    },
    userPhoto: {
        width: '200px',
        height: '200px',
        fontSize: '80px',
        margin: '0px 20px 20px 20px'
    },
    userName: {
        ...theme.typography.title_h2,
    },
    userRole: {
        padding: '4px 0px',
        ...theme.typography.subtitle_h1,
    },
    icons: {
        fontSize: '19px',
        marginRight: '10px',
        color: '#B0191A'
    },
    button: {
        margin: '25px 0px 0px auto !important',
    },
    progress: {
        color: '#B0191A'
    },

    card: {
        width: "400px",
        // height: "100px",
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        borderRadius: 10,
        backgroundColor: "#FFFFFF",
        position: "relative",
        margin: "0px 20px 20px 0px",
        backgroundColor: "#FFFFFF",
        boxShadow: '1px 1px 10px #EEEEEE'
    },
    cell: {
        flex: '1',
        padding: '7px 0px',
    },
    title: {
        flex: 0.3,
        padding: '5px 15px 0px 15px',
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: '20px',
            fontWeight: 700,
            color: '#474747',
            margin: 0,
            display: '-webkit-box',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        },
    },
    subtitle: {
        display: 0.3,
        padding: '0px 15px 0px 15px',
        '& p': {
            fontSize: '12px',
            fontWeight: 300,
            lineHeight: '15px',
            color: '#474747',
            margin: 0,
            display: '-webkit-box',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        },
    },
    date: {
        display: 1,
        padding: '0px 15px',
        '& p': {
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '16px',
            color: '#474747',
            margin: 0,
            // display: '-webkit-box',
            // '-webkit-line-clamp': 1,
            // '-webkit-box-orient': 'vertical',
            // overflow: 'hidden',
        },
    },
    time: {
        display: 1,
        padding: '0px 5px',
        '& p': {
            fontSize: '14px',
            fontWeight: 300,
            lineHeight: '15px',
            color: '#474747',
            margin: 0,
            // display: '-webkit-box',
            // '-webkit-line-clamp': 1,
            // '-webkit-box-orient': 'vertical',
            // overflow: 'hidden',
        },
        '& span': {
            fontSize: '14px',
            fontWeight: 300,
            lineHeight: '15px',
            color: '#474747',
            margin: 0,
            // display: '-webkit-box',
            // '-webkit-line-clamp': 1,
            // '-webkit-box-orient': 'vertical',
            // overflow: 'hidden',
        },
    },
    description: {
        flex: 0.6,
        display: 'flex',
        padding: '0px 15px 15px 15px',
        '& p': {
            maxHeight: "20px",
            fontSize: '16px',
            fontWeight: 200,
            lineHeight: '17px',
            color: '#B3B3B3',
            margin: 0,
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        },
    },
    empty: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0px 15px 15px 15px',
        '& p': {
            fontSize: '18px',
            fontWeight: 300,
            lineHeight: '20px',
            color: '#B3B3B3',
            margin: 0,
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        },
    },
})

export default Styles;