/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput) {
    createUser(input: $input) {
      sub
      username
      enabled
      status
      createAt
      given_name
      family_name
      name
      picture
      email
      groups
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup($input: CreateGroupInput) {
    createGroup(input: $input) {
      groupName
      description
      userGroupId
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput) {
    updateUser(input: $input) {
      statusCode
      message
    }
  }
`;
export const removeUserFromGroup = /* GraphQL */ `
  mutation RemoveUserFromGroup($input: RemoveUserFromGroupInput) {
    removeUserFromGroup(input: $input) {
      statusCode
      message
    }
  }
`;
export const updateChannelStatus = /* GraphQL */ `
  mutation UpdateChannelStatus($input: UpdateChannelInput) {
    updateChannelStatus(input: $input) {
      errorMessage
      errorCode
    }
  }
`;
export const removeContentFromGrid = /* GraphQL */ `
  mutation RemoveContentFromGrid($input: RemoveContentFromGridInput) {
    removeContentFromGrid(input: $input) {
      errorMessage
      errorCode
      name
      position
    }
  }
`;
export const extendContentFromGrid = /* GraphQL */ `
  mutation ExtendContentFromGrid($input: ExtendContentFromGridInput) {
    extendContentFromGrid(input: $input) {
      errorMessage
      errorCode
      name
      position
    }
  }
`;
export const startEvent = /* GraphQL */ `
  mutation StartEvent {
    startEvent {
      errorMessage
      errorCode
    }
  }
`;
export const stopEvent = /* GraphQL */ `
  mutation StopEvent {
    stopEvent {
      errorMessage
      errorCode
    }
  }
`;
export const syncSignalingChannels = /* GraphQL */ `
  mutation SyncSignalingChannels {
    syncSignalingChannels {
      errorMessage
      errorCode
    }
  }
`;
export const reportEnteredApp = /* GraphQL */ `
  mutation ReportEnteredApp($input: ReportEnteredAppInput) {
    reportEnteredApp(input: $input) {
      errorMessage
      errorCode
    }
  }
`;
export const reportQrCodeUsed = /* GraphQL */ `
  mutation ReportQrCodeUsed {
    reportQRCodeUsed {
      errorMessage
      errorCode
    }
  }
`;
export const startStreamingEvent = /* GraphQL */ `
  mutation StartStreamingEvent {
    startStreamingEvent {
      errorMessage
      errorCode
    }
  }
`;
export const resetAllChannels = /* GraphQL */ `
  mutation ResetAllChannels {
    resetAllChannels {
      errorMessage
      errorCode
    }
  }
`;
export const writePositionContents = /* GraphQL */ `
  mutation WritePositionContents($input: WritePositionContentsInput) {
    writePositionContents(input: $input) {
      errorMessage
      errorCode
    }
  }
`;
export const removeFromQueue = /* GraphQL */ `
  mutation RemoveFromQueue($input: RemoveFromQueueInput) {
    removeFromQueue(input: $input) {
      errorMessage
      errorCode
      name
    }
  }
`;
export const createBoModule = /* GraphQL */ `
  mutation CreateBoModule(
    $input: CreateBOModuleInput!
    $condition: ModelBOModuleConditionInput
  ) {
    createBOModule(input: $input, condition: $condition) {
      id
      name
      description
      enabled
      order
      submodules {
        id
        featureId
        featureType
        name
        description
        enabled
        order
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBoModule = /* GraphQL */ `
  mutation UpdateBoModule(
    $input: UpdateBOModuleInput!
    $condition: ModelBOModuleConditionInput
  ) {
    updateBOModule(input: $input, condition: $condition) {
      id
      name
      description
      enabled
      order
      submodules {
        id
        featureId
        featureType
        name
        description
        enabled
        order
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBoModule = /* GraphQL */ `
  mutation DeleteBoModule(
    $input: DeleteBOModuleInput!
    $condition: ModelBOModuleConditionInput
  ) {
    deleteBOModule(input: $input, condition: $condition) {
      id
      name
      description
      enabled
      order
      submodules {
        id
        featureId
        featureType
        name
        description
        enabled
        order
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMediaGridContent = /* GraphQL */ `
  mutation CreateMediaGridContent(
    $input: CreateMediaGridContentInput!
    $condition: ModelMediaGridContentConditionInput
  ) {
    createMediaGridContent(input: $input, condition: $condition) {
      PK
      SK
      status
      startDisplayTimestamp
      endDisplayTimestamp
      contentRef
      arn
      name
      disconnectTimestamp
      connectTimestamp
      updatedAt
      count
      creationTimestamp
      appearedTimestamp
      createdAt
    }
  }
`;
export const updateMediaGridContent = /* GraphQL */ `
  mutation UpdateMediaGridContent(
    $input: UpdateMediaGridContentInput!
    $condition: ModelMediaGridContentConditionInput
  ) {
    updateMediaGridContent(input: $input, condition: $condition) {
      PK
      SK
      status
      startDisplayTimestamp
      endDisplayTimestamp
      contentRef
      arn
      name
      disconnectTimestamp
      connectTimestamp
      updatedAt
      count
      creationTimestamp
      appearedTimestamp
      createdAt
    }
  }
`;
export const deleteMediaGridContent = /* GraphQL */ `
  mutation DeleteMediaGridContent(
    $input: DeleteMediaGridContentInput!
    $condition: ModelMediaGridContentConditionInput
  ) {
    deleteMediaGridContent(input: $input, condition: $condition) {
      PK
      SK
      status
      startDisplayTimestamp
      endDisplayTimestamp
      contentRef
      arn
      name
      disconnectTimestamp
      connectTimestamp
      updatedAt
      count
      creationTimestamp
      appearedTimestamp
      createdAt
    }
  }
`;
export const createEventSchedule = /* GraphQL */ `
  mutation CreateEventSchedule(
    $input: CreateEventScheduleInput!
    $condition: ModelEventScheduleConditionInput
  ) {
    createEventSchedule(input: $input, condition: $condition) {
      id
      name
      description
      startTimestamp
      endTimestamp
      dates {
        startTimestamp
        endTimestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEventSchedule = /* GraphQL */ `
  mutation UpdateEventSchedule(
    $input: UpdateEventScheduleInput!
    $condition: ModelEventScheduleConditionInput
  ) {
    updateEventSchedule(input: $input, condition: $condition) {
      id
      name
      description
      startTimestamp
      endTimestamp
      dates {
        startTimestamp
        endTimestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventSchedule = /* GraphQL */ `
  mutation DeleteEventSchedule(
    $input: DeleteEventScheduleInput!
    $condition: ModelEventScheduleConditionInput
  ) {
    deleteEventSchedule(input: $input, condition: $condition) {
      id
      name
      description
      startTimestamp
      endTimestamp
      dates {
        startTimestamp
        endTimestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const createLog = /* GraphQL */ `
  mutation CreateLog(
    $input: CreateLogInput!
    $condition: ModelLogConditionInput
  ) {
    createLog(input: $input, condition: $condition) {
      PK
      SK
      timestamp
      description
      reference
      createdAt
      updatedAt
    }
  }
`;
export const updateLog = /* GraphQL */ `
  mutation UpdateLog(
    $input: UpdateLogInput!
    $condition: ModelLogConditionInput
  ) {
    updateLog(input: $input, condition: $condition) {
      PK
      SK
      timestamp
      description
      reference
      createdAt
      updatedAt
    }
  }
`;
export const deleteLog = /* GraphQL */ `
  mutation DeleteLog(
    $input: DeleteLogInput!
    $condition: ModelLogConditionInput
  ) {
    deleteLog(input: $input, condition: $condition) {
      PK
      SK
      timestamp
      description
      reference
      createdAt
      updatedAt
    }
  }
`;
