import React, { useState, Children, useEffect } from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, ListItemText, Collapse, Tooltip } from '@material-ui/core';
import { ExpandLess, ExpandMore, } from '@material-ui/icons';

import { useSideMenu } from '../..';

import Styles from './style';

const useStyles = makeStyles(Styles);

const MenuItem = (props) => {

    const classes = useStyles();
    const [menu, setMenu] = useSideMenu();
    const [state, setState] = useState({
        path: props.path,
        isActive: false,
        nest: props.children,
        collapse: false,
    });

    useEffect(() => {
        setState({ ...state, isActive: props.location.includes(state.path) });
    }, [props.location])

    const collapseList = () => {
        if (Boolean(state.nest)) {
            setState({ ...state, collapse: !state.collapse });
        }
    }

    const onClickPrimaryItemHandler = () => {
        if (Boolean(state.nest)) {
            setMenu({ ...menu, open: true });
            collapseList();
        } else if (!state.isActive) {
            props.onClick();
        }
    }

    const collapseRender = () => {
        return (
            <Collapse in={props.open ? state.collapse : false} timeout="auto" unmountOnExit>
                <List component="div">
                    {Children.map(props.children, (child) => React.cloneElement(child, { parent: { ...state, ...props } }))}
                </List>
            </Collapse>
        );
    }

    return (
        <>
            <Tooltip arrow title={props.label} placement="right" disableHoverListener={props.open}>
                <ListItem
                    button={!(props.location == props.path)}
                    classes={{
                        root: clsx({
                            [classes.item]: true,
                            [classes.itemActive]: state.isActive
                        }),
                    }}
                    onClick={onClickPrimaryItemHandler}
                >
                    <ListItemIcon>{props.icon}</ListItemIcon>
                    <ListItemText primary={props.label} />
                    {Boolean(state.nest) ? (state.collapse ? <ExpandLess /> : <ExpandMore />) : null}
                </ListItem>
            </Tooltip>
            {
                props.children ? collapseRender() :  null
            }
        </>
    );
}

export default MenuItem;