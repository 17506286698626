const Styles = theme => ({
    root: {
        width: '80%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        width: '100%',
        display: 'flex',
        padding: '10px 0px ',
        ...theme.typography.title_h1_reg,
    },
    body: {
        display: 'flex',
        width: "100%",
        backgroundColor: 'gold'
    }
})

export default Styles;