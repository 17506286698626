const Styles = theme => ({
    main: {
        maxWidth: '100vw',
        height: '100vh',
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        backgroundColor: '#FFF'
    },
    header: {
        maxWidth: '100vw',
        display: 'flex',
        position: 'relative',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    content: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '10px 0px 50px 0px',
        '& img': {
            height: '55px'
        }
    },
    form: {
        width: '100%'
    },
    paper: {
        maxWidth: '375px',
    },
    footer: {
        width: '100%',
        padding: '60px 0px 20px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        '& div img': {
            width: '80px',
            padding: '0px 7px'
        }
    },
    icons: {
        fontSize: '19px',
        marginRight: '10px',
        color: '#B0191A'
    },
    progress: {
        color: '#B0191A'
    },
    title: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: '7px',
        ...theme.typography.title_h2,
    },
    subtitle: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...theme.typography.subtitle,
    },
    subtext: {
        justifyContent: 'flex-start',
        ...theme.typography.label,
    },
    anchorText: {
        cursor: 'pointer',
        justifyContent: 'flex-start',
        ...theme.typography.anchor,
    }
})

export default Styles;