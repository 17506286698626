const Styles = theme => ({
    root: {
        width: '80%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column'
    },
    title: {
        width: '100%',
        display: 'flex',
        padding: '10px 0px ',
        ...theme.typography.title_h1_reg,
    },
    label: {
        width: '100%',
        height: 'auto',
        fontSize: '27px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...theme.typography.subtitle,
    },
    grid: {
        flexGrow: 1
    },
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        margin: '10px 0px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',
        '& p': {
            margin: '0px',
            padding: '0px',
            color: '#B0191A',
        }
    },
    columnContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.between('0', '600')]: {
            flexDirection: 'column',
        },
    },
    column: {
        flex: '1',
        minWidth: '300px',
        [theme.breakpoints.between('0', '600')]: {
            minWidth: 'auto',
        },
        padding: '0px 7px',
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '15px',
        position: 'relative',
        maxWidth: '1232px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    userPhoto: {
        width: '200px',
        height: '200px',
        fontSize: '80px',
        margin: '0px 20px 20px 20px'
    },
    userName: {
        ...theme.typography.title_h2,
    },
    userRole: {
        padding: '4px 0px',
        ...theme.typography.subtitle_h1,
    },
    icons: {
        fontSize: '19px',
        marginRight: '10px',
        color: '#B0191A',
    },
    iconsTag: {
        fontSize: '20px',
        color: '#FFFFFF',
    },
    button: {
        margin: '25px 0px 0px auto !important',
    },
    progress: {
        color: '#B0191A'
    },
    textArea: {
        "& .MuiOutlinedInput-root": {
            width: '100% !important',
            height: '100% !important',
            alignItems: 'flex-start'
        },
    },
    checkbox: {
        color: "brown",
    },
    dropdown: {
        minWidth: '100%',
        marginTop: '10px',
        marginBottom: '10px',
        "$ .MuiSelect-select": {
            backgroundColor: 'red'
        }
    },
    dropdownSelect: {
        backgroundColor: 'transparent',
        "&:focus": {
            backgroundColor: 'transparent',
        }
    },
    inputLanguage: {
        width: '130px',
        height: '50px',
        padding: '0px 5px',
        position: 'absolute',
        top: '-50px',
        right: '15px',
        borderRadius: '7px 7px 0px 0px',
        backgroundColor: '#B0191A'
    },
    switchBox: {
        margin: '20px 0',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    boxImage: {
        height: 280,
        width: '100%',
        paddingLeft: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            border: `1px solid rgba(219, 67, 50, 1)`,
            borderRadius: 10,
            background: 'rgba(219, 67, 50, 1)'
        }
    },
    fileDropzoneBox: {
        width: '100%'
    },
    labelUploadImage: {
        margin: '10px 0 20px',
        cursor: 'pointer'
    },
})

export default Styles;