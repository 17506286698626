import React, { useEffect, useState } from "react";

// i18 Next
import { useTranslation } from "react-i18next";

// React router
import { useLocation } from "react-router";

//Material
import { makeStyles, Typography, Breadcrumbs} from '@material-ui/core';

// Util
import * as Utils from "../../../service/utils";

const BreadCrumbs = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  const location = useLocation();

  const [links, setLink] = useState([]);

  useEffect(() => {
    setLink(Utils.getLinksSplited(location.pathname));
  }, [location]);

  return (
    <Breadcrumbs aria-label="breadcrumb" separator="" className={classes.root}>
      {links.map((link) => (
        <Typography key={link}>{decodeURI(t(link))}</Typography>
      ))}
    </Breadcrumbs>
  );
};

export default BreadCrumbs;

const useStyles = makeStyles({
  root: {
    padding: "24px 0",
    "& .MuiBreadcrumbs-separator": {
      width: "4px",
      height: "4px",
      borderRadius: "50%",
      backgroundColor: "rgb(145, 158, 171)",
    },
  },
});
