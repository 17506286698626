const Styles = theme => ({
    root: {
        // flex: 1,
        // minWidth: "384px",
        // height: "540px",
        // minHeight: "360px",
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        //boder: '1px solid gray'
        //border: '2px solid #ffffff', 
    },
    video: {
        // flex: 1,
        // minWidth: "384px",
        // height: "540px",
        // minHeight: "360px",
        objectFit: 'cover',
        // display: 'none',
    },
    actionRemove: {
        position: 'absolute',
        width: '40px',
        height: '40px',
        backgroundColor: '#B0191A',
        borderRadius: "40px",
        bottom: 20,
        right: 20,
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        cursor: 'pointer'
    }, 
    actionExtend: {
        position: 'absolute',
        width: '40px',
        height: '40px',
        backgroundColor: '#FFFFFF',
        borderRadius: "40px",
        bottom: 20,
        right: 70,
        display: 'flex',
        justifyContent: "center",
        alignItems: "center",
        cursor: 'pointer'
    }
})

export default Styles;