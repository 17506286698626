import React, { useEffect, useContext, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ServiceContext } from '../../../context/services/service-context';
import { AuthContext } from '../../../context/auth/auth-context';
import { ChannelFeed } from '../../../components';
import { VIDEOWALL } from '../../../layout/config/videowall.config';

import Styles from './style';

const useStyles = makeStyles(Styles);

const WIDTH = VIDEOWALL.resolution.width;
const HEIGHT = VIDEOWALL.resolution.height;
const BACKGROUND = VIDEOWALL.background;
const LOGOS = VIDEOWALL.logos
const AUDIO = VIDEOWALL.audio;
const QRCODE = VIDEOWALL.qrcode;
const CHANNEL_WIDTH = VIDEOWALL.channels.width;
const CHANNEL_HEIGHT = VIDEOWALL.channels.height;
const MAX_FEED_CHANNELS = VIDEOWALL.channels.total;
const REQUEST_INTERVAL = VIDEOWALL.request_interval;
const TRANSFORM = VIDEOWALL.channels.positions;
const DEBUG = VIDEOWALL.debug;
const DISPLAY_CENTER = VIDEOWALL.displayCenter;

const ViewerView = () => {

    const classes = useStyles();
    const channelRefs = useRef([]);
    const audioRef = useRef(null);
    const interval = useRef(null);
    const { VideoStream } = useContext(ServiceContext);
    const { signin, currentUserCredentials, currentUserSession } = useContext(AuthContext);
    const [credentials, setCredentials] = useState(null);
    const [status, setStatus] = useState(null);
    const [positions, setPositions] = useState([]);
    const [videowallStatus, setVideowallStatus] = useState(false);


    const [requestFeeds, setRequestFeeds] = useState([]);
    const [channelFeeds, setChannelFeeds] = useState([]);


    useEffect(() => {
        generatePositionsArray();
        authAnonymousUser();
    }, [])


    useEffect(() => {
        const checkStatus = setInterval(() => {
            getEventStatus();
        }, REQUEST_INTERVAL);
        return () => clearInterval(checkStatus);
    }, []);


    useEffect(() => {
        if (credentials && status) {
            interval.current = setInterval(() => getAvailableChannels(positions), REQUEST_INTERVAL);
        }
        return () => clearInterval(interval.current);
    }, [credentials, status]);


    useEffect(() => {
        filterChanges(requestFeeds, channelFeeds)
    }, [requestFeeds])


    useEffect(() => {
        if (!status) {
            serviceStopedHandler();
        }
    }, [status])


    const authAnonymousUser = async () => {
        try {
            let haveSession = await currentUserSession();
            let haveCredentials = await currentUserCredentials();
            if (!haveSession) {
                let { data } = await VideoStream.getAnonymousUser();
                await signin(data.getAnonymousUser.username, data.getAnonymousUser.password);
                haveCredentials = await currentUserCredentials();
            }
            setCredentials(haveCredentials);
        } catch (error) {
            throw error
        }
    }

    const getEventStatus = async () => {
        const status = await VideoStream.getEventStatus();
        setStatus(status === "ACTIVE" ? true : false);
        if (DEBUG) console.log("Event Status:", status);
        if (DEBUG) console.log("Videowall Status:", videowallStatus);
    }


    const getAvailableChannels = async (positionList) => {
        try {
            let channels = await VideoStream.getPositionContents(positionList, true);
            if (channels) {
                setRequestFeeds(channels);
                if (!videowallStatus) {
                    setVideowallStatus(true);
                    audioRef.current.play();
                }
            }
            if (DEBUG) console.log("Get Available Channels:", channels);

        } catch (error) {
            throw error
        }
    }

    const generatePositionsArray = () => {

        let array = []
        for (let i = 0; i < MAX_FEED_CHANNELS; i++) {
            array.push(`position_${i}`);
        }
        setPositions(array);
    }


    const filterChanges = (newArray, prevArray) => {
        const array = newArray.filter(itemPrev => {
            return !prevArray.some(itemNew => {
                return (itemPrev.name === itemNew.name && itemPrev.startDisplayTimestamp === itemNew.startDisplayTimestamp)
            })
        });
        if (array.length > 0) {
            updateChannelFeed(array);
        }
        setChannelFeeds(newArray);
        if (DEBUG) {
            console.log("PREV: ", prevArray);
            console.log("NEW: ", newArray);
            console.log("FILTERED: ", array);
            console.log("----------------------------------------------------------------------------");
        }
    }

    const updateChannelFeed = async (updateList) => {
        updateList.forEach(element => {
            let position = channelRefs.current.find(item => item.position === element.position);
            if (position && element.name !== null) {
                position.reset(element.position, element.name, credentials, "videowall");
            } else {
                position.stop();
            }
        });
        if (DEBUG) {
            console.log("UPDATE LIST WITH: ", updateList);
        }
    }

    const serviceStopedHandler = async () => {
        if (channelFeeds.length > 0) {
            channelRefs.current.forEach(async (element) => {
                await element.stop();
            });
            setChannelFeeds([]);
            if (DEBUG) console.log("Channel Feeds: CLEARED");
        }
        setVideowallStatus(false);
        audioRef.current.pause();
    }


    const playAudio = () => {
        if (videowallStatus) {
            audioRef.current.play();
        }
    }

    return (
        <div className={classes.root} style={{ alignItems: DISPLAY_CENTER ? 'center' : 'unset', justifyContent: DISPLAY_CENTER ? 'center' : 'unset', backgroundColor: BACKGROUND.color }}>
            <div className={classes.body} style={{ width: `${WIDTH}px`, height: `${HEIGHT}px` }}>
                <div className={classes.feedsContainer}>
                    {
                        positions.map((prop, index) =>
                            <ChannelFeed
                                channelRef={(value) => channelRefs.current.push(value)}
                                key={index}
                                id={prop}
                                width={CHANNEL_WIDTH}
                                height={CHANNEL_HEIGHT}
                                transform={TRANSFORM[index]}
                                debugChannel={DEBUG}
                                debug
                            />
                        )
                    }
                    {
                        QRCODE.status ?
                            <div className={classes.qrcodeBox}
                                style={{
                                    width: `${QRCODE.width}px`,
                                    height: `${QRCODE.height}px`,
                                    position: 'absolute',
                                    top: QRCODE.position.top,
                                    left: QRCODE.position.left
                                }}>
                                <img src={QRCODE.src} alt="" style={{ width: "100%" }} />
                            </div>
                            : null
                    }
                </div>
                <div className={classes.backgroundCover} style={{ width: "100%" }}>
                    {
                        BACKGROUND.status && (BACKGROUND.type === 'VIDEO') ? <video src={BACKGROUND.src} autoPlay={true} muted loop /> : null
                    }
                    {
                        BACKGROUND.status && (BACKGROUND.type === 'IMAGE') ? <img src={BACKGROUND.src} style={{ width: "100%" }} alt="" /> : null
                    }
                    {
                        AUDIO.status ? <audio ref={audioRef} src={AUDIO.src} style={{ display: 'none' }} loop controls autoPlay /> : null
                    }
                </div>
                {
                    LOGOS.top_left.status ?
                        <img src={LOGOS.top_left.src} alt="" style={{ width: '179px', height: '44px', position: 'absolute', top: 39, left: 39, zIndex: 1000 }} />
                        : null
                }
                {
                    LOGOS.middle_bottom.status ?
                        <div onClick={playAudio}><img src={LOGOS.middle_bottom.src} alt="" style={{ width: '137px', height: '56px', position: 'absolute', bottom: 20, left: 0, zIndex: 1000, right: 0, marginLeft: 'auto', marginRight: 'auto' }} /></div>
                        : null
                }
                {
                    LOGOS.top_right.status ?
                        <img src={LOGOS.top_right.src} alt="" style={{ width: '298px', position: 'absolute', top: 40, right: 38, zIndex: 1000 }} />
                        : null
                }
                {
                    !videowallStatus ? <div className={classes.connectionPoint} /> : null
                }
                {/*    OVERLAY  */}
                {/* < className={classes.overlay} style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}/> */}
                {/* ------------- */}
            </div>
        </div>
    );
}

export default ViewerView;
