import MuiTextField from './MuiTextField';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiButton from './MuiButton';
import MuiPaper from './MuiPaper';
import MuiDrawer from './MuiDrawer';
import MuiToolbar from './MuiToolbar';
import MuiTab from './MuiTab';
import MuiDataGrid from "./MuiDataGrid";
import MuiDialog from './MuiDialog';


const overrides =  {
    MuiTextField,
    MuiOutlinedInput,
    MuiButton,
    MuiPaper,
    MuiDrawer,
    MuiToolbar,
    MuiTab,
    MuiDataGrid,
    MuiDialog
}

export default overrides;