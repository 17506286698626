const Styles = theme => ({
    item: {
        margin: '7px 0px',
        borderRadius: '10px',
        '& .MuiListItemIcon-root': {
            color: '#C2C2C2',
        },
        '& .MuiListItemText-root': {
            color: '#C2C2C2',
        },
        '& .MuiSvgIcon-root':{
            color: '#C2C2C2',
        }
    },
    itemActive: {
        backgroundColor: '#B0191A',
        '& .MuiListItemIcon-root': {
            color: 'white',
        },
        '& .MuiListItemText-root': {
            color: 'white',
        },
        '& .MuiSvgIcon-root':{
            color: 'white',
        }
    }
})

export default Styles;