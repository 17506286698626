import React, { useState, useContext, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ServiceContext } from '../../../../../../context/services/service-context';

import * as Utils from '../../../../../../service/utils'
import Styles from './style.js';

const useStyles = makeStyles(Styles);

const ScheduleListView = () => {

    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { VideoStream } = useContext(ServiceContext);
    const [schedules, setSchedules] = useState(null)

    useEffect(() => {
        getEventScheduleList();
    }, [])

    const getEventScheduleList = async () => {
        let schedules = await VideoStream.eventSchedulesList();
        // schedules.sort((a, b) => a.startTimestamp - b.startTimestamp);
        setSchedules(schedules);
        console.log(schedules)
    }

    async function onRemoveScheduleHandler(id) {
        let schedule = await VideoStream.deleteEventSchedule(id);
        if (schedule) {
            await getEventScheduleList()
        }
    }

    async function onEditScheduleHandler(data) {
        navigate(`/bo/video-stream/schedule/${data.id}`, { state: { ...data } })
    }

    return (
        <div className={classes.root}>
            {
                schedules ?
                    schedules.length > 0 ?
                        schedules.map((prop, index) =>
                            <div key={`schedule-card-${index}`} className={classes.card}>
                                <div onClick={() => onEditScheduleHandler(prop)} style={{ cursor: 'pointer', color: "#B0191A", width: "30px", height: "30px", position: 'absolute', top: 10, right: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Edit /></div>
                                <div onClick={() => onRemoveScheduleHandler(prop.id)} style={{ cursor: 'pointer', color: "#B0191A", width: "30px", height: "30px", position: 'absolute', top: 10, right: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Delete /></div>
                                <div className={classes.title}><p>{prop.name}</p></div>
                                <div style={{ height: 10 }} />

                                {/* LEGACY - TO BE DELETED */}
                                {
                                    prop.startTimestamp && prop.endTimestamp ?
                                        <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
                                            <div className={classes.cell} style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div className={classes.date}><p>{Utils.getDateStringFormat(prop.startTimestamp / 1000)}</p></div>
                                                <div className={classes.time}><p>{Utils.getTime(prop.startTimestamp / 1000)}</p></div>
                                            </div>
                                            <div className={classes.cell} style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div className={classes.date}><p>{Utils.getDateStringFormat(prop.endTimestamp / 1000)}</p></div>
                                                <div className={classes.time}><p>{Utils.getTime(prop.endTimestamp / 1000)}</p></div>
                                            </div>
                                        </div>
                                        : null

                                }
                                {
                                    prop.dates ?
                                        prop.dates.length > 0 ?
                                            prop.dates.map((prop, index) =>
                                                <div key={`schedule-card-date-${index}`} style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
                                                    <div className={classes.cell} style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div className={classes.date}><p>{Utils.getDateStringFormat(prop.startTimestamp / 1000)}</p></div>
                                                        <div className={classes.time}><p>{Utils.getTime(prop.startTimestamp / 1000)}</p></div>
                                                    </div>
                                                    <div className={classes.cell} style={{ display: 'flex', flexDirection: 'row' }}>
                                                        <div className={classes.date}><p>{Utils.getDateStringFormat(prop.endTimestamp / 1000)}</p></div>
                                                        <div className={classes.time}><p>{Utils.getTime(prop.endTimestamp / 1000)}</p></div>
                                                    </div>
                                                </div>
                                            )
                                            : null
                                        : null
                                }
                                <div style={{ height: 10 }} />
                                <div className={classes.description}><p>{prop.description}</p></div>
                            </div>
                        )
                        :
                        <div style={{ flex: 1, display: 'flex', height: "200px", justifyContent: 'center', alignItems: 'center' }}>
                            <div className={classes.empty}><p>{t('bo-schedule-list-empty-label')}</p></div>
                        </div>
                    :
                    <div style={{ flex: 1, display: 'flex', height: "200px", justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress size={40} className={classes.progress} />
                    </div>
            }
        </div>
    );
}

export default ScheduleListView;