import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, IconButton } from '@material-ui/core';
import { MenuRounded } from '@material-ui/icons';

import { SessionPopper, LanguageSelector } from '../../../components';
import { useSideMenu } from '../../../components/Backoffice/Sidemenu';

import Styles from './style';

const useStyles = makeStyles(Styles);

const ToolbarMenu = () => {

    const classes = useStyles();
    const [menu, setMenu] = useSideMenu();

    return (
        <Toolbar classes={{root: classes.root}}>
            <div className={classes.leftActions}>
                <IconButton onClick={() => setMenu({ ...menu, mobileOpen: !menu.mobileOpen })}>
                    <MenuRounded />
                </IconButton>
            </div>
            <div className={classes.rightActions}>
                <LanguageSelector/>
                <SessionPopper />
            </div>
        </Toolbar>
    );
}

export default ToolbarMenu;