import React, { useEffect, useContext } from 'react';
import { useSearchParams } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Styles from './style';
import TermsAndConditions from '../../components/VideoWall/TermsAndConditions';
import { useVideowall } from '../../context/Videowall';
import { AuthContext } from '../../context/auth/auth-context';
import { ServiceContext } from '../../context/services/service-context';
import Loading from '../../components/Shared/Loading';
import LanguageOptions from '../../components/VideoWall/LanguageOptions';
import Faqs from '../../components/VideoWall/Faqs';
import Schedule from '../../components/VideoWall/Schedule';

import Assets from '../../assets'


const useStyles = makeStyles(Styles);


const LayoutVideoWallMaster = ({
    children
}) => {

    const classes = useStyles();

    let [searchParams, setSearchParams] = useSearchParams();

    const { VideoStream } = useContext(ServiceContext);
    const { currentUserSession } = useContext(AuthContext);


    const {
        videowallLoading,
        termsAccepted,
        onAcceptTerms,
        onBackTerms,
        language,
        onSelectLang,
        showFaqs,
        setShowFaqs,
        showSchedule,
        setShowSchedule,
        showTerms,
        setShowTerms
    } = useVideowall()


    const sendReport = async (data) => {
        console.log('Send Report')
        const session = await currentUserSession()
        let type = 'AMAZON_COGNITO_USER_POOLS'
        if (!session) type = 'AWS_IAM'

        await VideoStream.reportEnteredApp(data, type)
    }


    useEffect(() => {
        let qrcodeParam = searchParams.get("qrcode");
        if (qrcodeParam) {
            const data = { qrCode: true}
            sendReport(data)
        } else {
            const data = { qrCode: false }
            sendReport(data)
        }
    }, [])


    if (videowallLoading) return <Loading />

    if (!language) return <LanguageOptions onSelect={(lang) => onSelectLang(lang)} />

    if (!termsAccepted) return <TermsAndConditions hiddenButtons={false} onAccept={(value) => onAcceptTerms(value)} onBack={() => onBackTerms()} />

    return (
        <React.Fragment>
            <div className={classes.root}>
                <div className={classes.header}>
                    <div className={classes.top}>
                        <img src={Assets.Images.videowall_logo_vodafone} alt="" height={22}/>
                        <img src={Assets.Images.videowall_logo_poweredby5g} alt="" />
                    </div>
                    <div className={classes.titleBox}>
                        <img src={Assets.Images.videowall_stage_cam} alt="" width={288}/>
                    </div>
                </div>
                {children}
            </div>
            {showFaqs && <Faqs onBack={() => setShowFaqs(false)} />}
            {showSchedule && <Schedule onBack={() => setShowSchedule(false)} />}
            {showTerms && <TermsAndConditions hiddenButtons={true} onAccept={(value) => onAcceptTerms(value)} onClose={() => setShowTerms()} />}
        </React.Fragment>
    );
}

export default LayoutVideoWallMaster; 
