import React, { createContext, useState, useContext } from "react";

const AlertContext = createContext(null);

const AlertProvider = (props) => {
  const [showAlert, setShowAlert] = useState(false);
  const [msg, setMsg] = useState("");
  const [type, setType] = useState(0);

  const onErrorAlert = (msg) => {
    setMsg(msg);
    setShowAlert(true);
    setType(0);
  };

  const onSuccessAlert = (msg) => {
    setMsg(msg);
    setShowAlert(true);
    setType(1);
  };

  return (
    <AlertContext.Provider
      value={{
        showAlert,
        setShowAlert,
        msg,
        type,
        onErrorAlert,
        onSuccessAlert,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

function useAlert() {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error("useApp must be used within an AppProvider.");
  }
  return context;
}

export { AlertProvider, useAlert };
