import React, { useEffect, useState, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { Stop, PlayArrowRounded, Grain, ViewComfyRounded } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { ChannelFeed } from '../../../../components';
import { ServiceContext } from '../../../../context/services/service-context';
import { AuthContext } from '../../../../context/auth/auth-context';
import { VIDEOWALL } from '../../../../layout/config/videowall.config';
import Styles from './style';

const useStyles = makeStyles(Styles);

const MAX_FEED_CHANNELS = VIDEOWALL.channels.total;
const MAX_QUEUE_CHANNELS = VIDEOWALL.queue.total;
const REQUEST_INTERVAL = VIDEOWALL.request_interval;
const TRANSFORM = VIDEOWALL.bo.preview.positions;
const DEBUG = VIDEOWALL.debug;
const QUEUE_OFFSET = VIDEOWALL.queueOffset;
const QRCODE = VIDEOWALL.qrcode;


const VideoStreamChannelView = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const timerRef = useRef(null);
    const channelRefs = useRef([]);
    const quequeRefs = useRef([]);
    const interval = useRef(null);
    const { VideoStream } = useContext(ServiceContext);
    const { currentUserCredentials, currentUserSession } = useContext(AuthContext);
    const [credentials, setCredentials] = useState(null);
    const [status, setStatus] = useState(false);
    const [videowallStatus, setVideowallStatus] = useState(false);
    const [positions, setPositions] = useState([]);
    const [timeCounter, setTimeCounter] = useState("0:00");
    const [requestFeeds, setRequestFeeds] = useState([]);
    const [channelFeeds, setChannelFeeds] = useState([]);
    const [requestQueue, setRequestQueue] = useState([])
    const [queueFeed, setQueueFeeds] = useState([])
    const [connectionCount, setConnectionCount] = useState(0);
    const [isPreview, setIsPreview] = useState(true);
    const [renders, setRenders] = useState([]);


    const [localQueue, setLocalQueue] = useState([]);

    const localQueueRefs = useRef([]);


    useEffect(() => {
        generatePositionsArray();
        authAnonymousUser();
        getEventStatus();
    }, []);

    useEffect(() => {
        if (credentials && status) {
            interval.current = setInterval(() => {
                getAvailableChannels(positions)
                getQueueContents(MAX_QUEUE_CHANNELS)
                getCurrentConnections();
            }, REQUEST_INTERVAL);
        }
        return () => clearInterval(interval.current);
    }, [credentials, status]);

    useEffect(() => {
        if (!status) {
            localQueueRefs.current = [];
            quequeRefs.current = [];
            // setRenders([]);
            setChannelFeeds([]);
            setQueueFeeds([])
        }
    }, [status]);


    useEffect(() => {
        filterChanges(requestFeeds, channelFeeds)
    }, [requestFeeds])


    // useEffect(() => {
    //     filterChangesQueue(requestQueue, queueFeed)
    // }, [requestQueue])


    const authAnonymousUser = async () => {
        try {
            let haveSession = await currentUserSession();
            let haveCredentials = await currentUserCredentials();
            // if (!haveSession) {
            //     let { data } = await VideoStream.getAnonymousUser();
            //     await signin(data.getAnonymousUser.username, data.getAnonymousUser.password);
            //     haveCredentials = await currentUserCredentials();
            // }
            setCredentials(haveCredentials);
        } catch (error) {
            throw error
        }
    }

    /**
     * Get the current status of the service
     */
    const getEventStatus = async () => {
        const status = await VideoStream.getEventStatus();
        setStatus(status === "ACTIVE" ? true : false);
    }

    /**
     * Request the channels that are going to be displayed on the videowall
     * @param {*} positionList 
     */
    const getAvailableChannels = async (positionList) => {
        try {
            let channels = await VideoStream.getPositionContents(positionList, false);
            if (channels) {
                setRequestFeeds(channels);
            }
            if (DEBUG) console.log("LIVE RESPONSE:", channels);
        } catch (error) {
            throw error
        }
    }

    /**
     * Request the channels that are in the queue
     * @param {*} maxFeeds 
     */
    const getQueueContents = async (maxFeeds) => {
        try {
            let requestQueue = await VideoStream.getQueueContents({ contentCount: maxFeeds });

            let filterQueue = requestQueue.filter(item => item.name != null);

            filterChangesQueue(filterQueue, localQueueRefs.current);

            if (DEBUG) console.log("QUEUE RESPONSE: ", requestQueue);
        } catch (error) {
            throw error
        }
    }

    /**
     * Generate the positions needed to request the channels to display on the videowall
     */
    const generatePositionsArray = () => {
        let array = []
        for (let i = 0; i < MAX_FEED_CHANNELS; i++) {
            array.push(`position_${i}`);
        }
        setPositions(array);
    }


    const updateLocalQueue = React.useCallback((newData) => {
        setLocalQueue((currentQueue) => {
            filterChangesQueue(newData, currentQueue)
        });
    }, []);



    const startServiceHandler = async () => {
        const result = await VideoStream.startEvent();
        setStatus(result ? true : status);
    }

    const startVideoWallHandler = async () => {
        await VideoStream.startStreamingEvent();
        timerStatus(true);
        setVideowallStatus(true)
    }

    const stopServiceHandler = async () => {
        await VideoStream.stopEvent();
        channelRefs.current.forEach(async (element) => {
            await element.stop();
        });
        setStatus(false);
        setVideowallStatus(false);
        setConnectionCount(0);
        timerStatus(false);
    }

    const filterChanges = (newArray, prevArray) => {
        const array = newArray.filter(itemPrev => {
            return !prevArray.some(itemNew => {
                return (itemPrev.name === itemNew.name && itemPrev.startDisplayTimestamp === itemNew.startDisplayTimestamp)
            })
        });
        if (array.length > 0) {
            updateChannelFeed(array);
        }
        setChannelFeeds(newArray);
        if (DEBUG) {
            console.log("PREV: ", prevArray);
            console.log("NEW: ", newArray);
            console.log("FILTERED: ", array);
            console.log("----------------------------------------------------------------------------");
        }
    }

    const updateChannelFeed = async (updateList) => {
        updateList.forEach(element => {
            let position = channelRefs.current.find(item => item.position === element.position);
            if (position && element.name !== null) {
                position.reset(element.position, element.name, credentials, "backoffice", "VIDEOWALL");
            } else {
                position.stop();
            }
        });
        if (DEBUG) console.log("UPDATE LIST WITH: ", updateList);
    }




    const filterChangesQueue = async (newArray, prevArray) => {
        let toAdd = compareArrays(newArray, prevArray, isChannelNameEqual);
        let toRemoved = compareArrays(prevArray, newArray, isChannelNameEqual);

        toAdd = toAdd.filter(item => item.name != null);

        if (toAdd.length > 0) {
            onAddQueueFeed(toAdd);
        }

        if (toRemoved.length > 0) {
            await onRequestDisconnection(toRemoved);
        }

        localQueueRefs.current = newArray;

        if (DEBUG) {
            console.log("----------------------------------------------------------------------------");
            console.log("---------------------------- |  QUEUE LIST  | ------------------------------");
            console.log("PREV: ", prevArray);
            console.log("NEW: ", newArray);
            console.log("ADD TO QUEUE: ", toAdd);
            console.log("REMOVE FROM QUEUE: ", toRemoved);
            console.log("----------------------------------------------------------------------------");
        }
    }

    const removeChannelFeed = async (position, channel) => {
        await VideoStream.removeContentFromGrid(position, channel);
    }


    const extendChannelFeed = async (position) => {
        const result = await VideoStream.extendContentFromGrid(position);
        if (DEBUG) console.log(result);
    }


    const getCurrentConnections = async () => {
        const result = await VideoStream.getStreamingUserCount();
        if (result?.count >= 0) {
            setConnectionCount(result.count);
        }
    }

    function timerStatus(status) {
        if (status) {
            let startTime = new Date().getTime();
            timerRef.current = setInterval(() => {
                timerCountHandler(startTime);
            }, 1000);
        } else {
            clearInterval(timerRef.current);
            setTimeCounter("0:00");
        }
    }

    const timerCountHandler = (startTime) => {
        var now = new Date().getTime();
        var distance = now - startTime;
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        let timeString = `${minutes}:${("0" + seconds).slice(-2)}`;
        setTimeCounter(timeString);
    }

    const switchView = (value) => {
        setIsPreview(value)
    }

    function isChannelNameEqual(a, b) {
        return a.name === b.name;
    }

    function compareArrays(left, right, compareFunction) {
        return left.filter(leftValue =>
            !right.some(rightValue =>
                compareFunction(leftValue, rightValue)));
    }

    function onAddQueueFeed(data) {
        console.log('ADDED TO QUEUE', data)

        setRenders(currentRenders => [...currentRenders, ...data]);
        // setQueueFeeds(currentQueue => [...currentQueue, ...data]);
        console.log("TOTAL REFs: ", quequeRefs);
    }


    async function onRequestDisconnection(data) {
        for (let i = 0; i < data.length; i++) {
            let elem = quequeRefs.current.find(item => item.channel === data[i].name);
            if (elem) {
                console.log("DISCONNECT COMPONENT: ", elem);
                await elem.stop();
            }
        }
    }


    async function onDisconnectionConfirmed(position, channel) {
        let channelComponent = document.getElementById(`${channel}_component`);
        if (channelComponent) {
            // setQueueFeeds(queueFeed.filter(item => item.name !== channel));
            quequeRefs.current = quequeRefs.current.filter(item => item.channel !== channel);
            channelComponent.remove();
        }
        console.log('REMOVED QUEUE', channel)
        console.log("REMAINING REFs: ", quequeRefs);
        console.log("RENDERS: ", renders);
    }

    async function kickFeedFromQueue(channel) {
        await VideoStream.removeFromQueue(channel);
    }

    // const deleteItem = React.useCallback((channel) => {
    //     setQueueFeeds((currentQueue) =>
    //         currentQueue.filter(item => item.name !== channel)
    //     );
    // }, [queueFeed]);


    return (
        <div className={classes.root}>

            <div className={classes.title}>{t('bo-transmission-title')}</div>

            <div className={classes.header}>
                <div className={classes.column} style={{ borderRight: '2px solid #F2F3F4', paddingRight: "30px" }}>
                    <div className={classes.cell}>
                        <Button
                            style={{ width: "220px", margin: '5px 0px' }}
                            startIcon={
                                status ? <Stop style={{ color: "white" }} /> : <PlayArrowRounded style={{ color: "white" }} />
                            }
                            variant="contained"
                            onClick={status ? stopServiceHandler : startServiceHandler}
                        > {status ? t('bo-transmission-service-off-btn-label') : t('bo-transmission-service-on-btn-label')} </Button>
                        <div style={{ width: "18px", height: "18px", borderRadius: 20, backgroundColor: status ? "green" : "red", margin: '0px 10px 0px 30px' }}></div>
                        <div style={{ fontSize: "18px", fontWeight: 200 }}>{status ? t('bo-transmission-status-on-label') : t('bo-transmission-status-off-label')}</div>
                    </div>
                    <div className={classes.cell}>
                        <Button
                            style={{ width: "220px", margin: '5px 0px' }}
                            startIcon={
                                videowallStatus ? <Stop style={{ color: "white" }} /> : <PlayArrowRounded style={{ color: "white" }} />
                            }
                            variant="contained"
                            onClick={status ? startVideoWallHandler : () => { }}
                            disabled={!status || videowallStatus}
                        >
                            {t('bo-transmission-videowall-on-btn-label')}
                        </Button>
                        <div style={{ width: "18px", height: "18px", borderRadius: 20, backgroundColor: videowallStatus ? "green" : "red", margin: '0px 10px 0px 30px' }}></div>
                        <div style={{ fontSize: "18px", fontWeight: 200 }}>{status && videowallStatus ? t('bo-transmission-status-on-label') : t('bo-transmission-status-off-label')}</div>
                    </div>
                </div>
                <div className={classes.column}>
                    <div className={classes.cell} style={{ justifyContent: 'flex-end' }}>
                        <span style={{ fontSize: "18px", fontWeight: 200 }}>{`${t('bo-transmission-connections-label')}: `}<span style={{ fontSize: "25px", fontWeight: 600 }}>{connectionCount}</span></span>
                    </div>
                    <div className={classes.cell} style={{ justifyContent: 'flex-end' }}>
                        <span style={{ fontSize: "18px", fontWeight: 200 }}>{`${t('bo-transmission-timer-label')}: `}<span style={{ fontSize: "25px", fontWeight: 600 }}>{timeCounter}</span></span>
                    </div>
                </div>
            </div>

            <div className={classes.header}>
                <div className={classes.column} style={{ paddingRight: "30px" }}></div>
                <div className={classes.column}>
                    {/* <div className={classes.cell} style={{ justifyContent: 'flex-end' }}>
                        <Button
                            style={{ width: "120px", margin: '5px 7px 0px 0px', justifyContent: 'flex-end' }}
                            startIcon={
                                videowallStatus ? <Stop style={{ color: "white" }} /> : <ViewComfyRounded style={{ color: "white" }} />
                            }
                            variant="contained"
                            onClick={() => switchView(false)}
                            disabled={!isPreview}>Grelha</Button>
                        <Button
                            style={{ width: "120px", margin: '5px 0px', justifyContent: 'flex-end' }}
                            startIcon={
                                videowallStatus ? <Stop style={{ color: "white" }} /> : <Grain style={{ color: "white" }} />
                            }
                            variant="contained"
                            onClick={() => switchView(true)}
                            disabled={isPreview}>Preview</Button>

                    </div> */}
                </div>
            </div>

            <div className={classes.body}>
                <div className={classes.subtitle} style={{ zIndex: 20 }}>{t('bo-transmission-videowall-title')}</div>
                <div className={classes.feedsContainer} >
                    {
                        positions.map((prop, index) =>
                            <div key={`videowall-feed-${index}`}>
                                <ChannelFeed
                                    channelRef={(value) => channelRefs.current.push(value)}
                                    key={index}
                                    id={prop}
                                    transform={isPreview ? TRANSFORM[index] : null}
                                    remove={removeChannelFeed}
                                    extend={extendChannelFeed}
                                    margin={isPreview ? 0 : 10}
                                    debug
                                    small
                                />
                            </div>
                        )
                    }
                    {
                        QRCODE.status ?
                            <div className={classes.qrcodeBox}
                                style={{
                                    width: "220px",
                                    height: "220px",
                                    position: 'absolute',
                                    top: 258,
                                    left: 690
                                }}>
                                <img src={QRCODE.src} alt="" style={{ width: "100%" }} />
                            </div>
                            : null
                    }
                </div>
                <div className={classes.queueContainer} style={{ top: isPreview ? QUEUE_OFFSET : 0, minHeight: "300px" }}>
                    <div className={classes.subtitle} style={{ zIndex: 20 }}>{t('bo-transmission-queue-title')}</div>
                    <div id="queue-list" className={classes.queueBoxList} style={{ top: isPreview ? 70 : 0, position: isPreview ? 'absolute' : 'relative' }}>
                        {
                            renders.map((prop, index) =>
                                <ChannelFeed
                                    channelRef={(value) => quequeRefs.current.push(value)}
                                    key={index}
                                    id={prop.name}
                                    debug
                                    small
                                    remove={onDisconnectionConfirmed}
                                    margin={10}
                                    autoStart={{
                                        position: prop.name,
                                        name: prop.name,
                                        credentials: credentials,
                                        origin: "backoffice",
                                    }}
                                    kick={() => kickFeedFromQueue(prop.name)}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        </div >
    );
}

export default VideoStreamChannelView;