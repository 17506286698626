import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, TextField, InputAdornment, IconButton, Button, CircularProgress, Dialog } from '@material-ui/core';
import { LockRounded, Visibility, VisibilityOff } from '@material-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { AuthContext } from '../../../context/auth/auth-context.js';
import { useNotifications } from '../../../components/Shared/NotificationSnackbar';
import Styles from './style';

import { useApp } from '../../../context/app';


const useStyles = makeStyles(Styles);

const NewPasswordRequiredPopup = ({ open}) => {

    const { setOpenPopupPasswordRequired, setOpenPopupLogin } = useApp();
    const { completeNewPassword } = useContext(AuthContext);
    const { triggerNotification } = useNotifications();
    const classes = useStyles();
    const { t } = useTranslation();

    const [values, setValues] = useState({ showPassword: false, showConfirmPassword: false });

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        onSubmit: async values => {
            try {
                if (values.password !== values.confirmPassword) {
                    triggerNotification("warning", "notifications-default-MatchPassword-message", "notifications-default-MatchPassword-title")
                    return;
                }
                await completeNewPassword(values.confirmPassword);
                setOpenPopupLogin(true)
            } catch (error) {
                throw error;
            }
        },
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleClickShowConfirmPassword = () => {
        setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
    };

    return (

        <Dialog
            className={classes.dialog}
            maxWidth="md"
            disableBackdropClick={false}
            onClose={() => setOpenPopupPasswordRequired(false)}
            open={open}
            aria-labelledby="popup-info-title"
            aria-describedby="popup-info-description"
        >
        
                <Paper className={classes.paper} elevation={0}>
                    <div className={classes.title}>{t('newpassword-text-title')}</div>
                    <div className={classes.subtitle}>{t('newpassword-text-subtitle')}</div>
                    <br />
                    <form onSubmit={formik.handleSubmit} className={classes.form}>
                        <TextField
                            id="password"
                            type={values.showPassword ? 'text' : 'password'}
                            placeholder={t('newpassword-input-password')}
                            variant="outlined"
                            InputProps={{
                                startAdornment: <LockRounded className={classes.icons} />,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end">
                                        {values.showPassword ? <Visibility className={classes.icons} /> : <VisibilityOff className={classes.icons} />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                            onChange={formik.handleChange}
                            error={formik.errors.password && formik.touched.password}
                            helperText={(formik.errors.password && formik.touched.password) && formik.errors.password}
                            required
                        />
                        <TextField
                            id="confirmPassword"
                            type={values.showConfirmPassword ? 'text' : 'password'}
                            placeholder={t('newpassword-input-confirm-password')}
                            variant="outlined"
                            InputProps={{
                                startAdornment: <LockRounded className={classes.icons} />,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        edge="end">
                                        {values.showConfirmPassword ? <Visibility className={classes.icons} /> : <VisibilityOff className={classes.icons} />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                            onChange={formik.handleChange}
                            error={formik.errors.confirmPassword && formik.touched.confirmPassword}
                            helperText={(formik.errors.confirmPassword && formik.touched.confirmPassword) && formik.errors.confirmPassword}
                            required
                        />
                        <Button type='submit' variant="contained" disableElevation disabled={formik.isSubmitting}>{
                            formik.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('newpassword-btn-text')
                        }</Button>
                    </form>
            </Paper>
            
        </Dialog>

         
    );
}

export default NewPasswordRequiredPopup;