const Styles = theme => ({
    root: {
        width: '80%',
        height: 'auto',
    },
    title: {
        width: '100%',
        display: 'flex',
        padding: '10px 0px ',
        ...theme.typography.title_h1_reg,
    },
})

export default Styles;