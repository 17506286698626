import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { makeStyles } from '@material-ui/core';
import Footer from '../../../../components/VideoWall/Footer';
import { useVideowall } from '../../../../context/Videowall';
import { ServiceContext } from '../../../../context/services/service-context';
import { stopMaster } from '../master';
import Assets from '../../../../assets';


const NextEvent = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const { VideoStream } = useContext(ServiceContext);
    const { setShowSchedule } = useVideowall()
    const [nextEvent, setNextEvent] = useState(null)



    useEffect(() => {
        const fetchEventSchedule = async () => {
            try {
                const schedules = await VideoStream.listEventSchedulesCloudfront()
                let dates = [];
                schedules.forEach(it => {
                    dates = [...dates, ...it.dates]
                });
                let schedulesSorted = dates.sort((a, b) => a.startTimestamp - b.startTimestamp);
                let scheduleFiltered = schedulesSorted.filter(date => date.startTimestamp >= new Date().getTime())
                let dataObject = {}
                scheduleFiltered?.forEach(it => {
                    const date = new Date(it.startTimestamp)
                    const key = `${date.getFullYear()}_${date.getMonth()}_${date.getDate()}`
                    if (dataObject[key]) {
                        dataObject[key] = [...dataObject[key], it.startTimestamp]
                    } else {
                        dataObject[key] = [it.startTimestamp]
                    }
                })
                const objectValues = Object.values(dataObject);
                if (objectValues?.length) {
                    const next = objectValues[0]
                    setNextEvent(next[0])
                }
            } catch (error) {
                setNextEvent()
            }
        }
        stopMaster()
        fetchEventSchedule()
    }, [])


    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <h4>{t('videowall-app-next-event-title')}</h4>
                <div className={classes.infoHeaderBox}>
                    {nextEvent && (
                        <div className={classes.infoHeaderBoxDate}>
                            <h3>{moment(nextEvent).format('DD/MM - HH:mm')}</h3>
                        </div>
                    )}
                    <span onClick={() => setShowSchedule(true)}>
                        {t('videowall-app-next-event-view-event')}
                    </span>
                </div>
            </div>
            <div className={classes.content}>
                <div className={classes.infoBox}>
                    <span>{t('videowall-app-next-event-info')}</span>
                </div>
                <div className={classes.dashBox}>
                    <img src={Assets.Images.videowall_dashed_line} alt="" />
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default NextEvent


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        padding: '0px',
        flexDirection: 'column'
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '90px',
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4))',
        '& h4': {
            color: '#FFF',
            margin: 0,
            font: 'normal normal bold 21px/21px VodafoneRg',
            whiteSpace: 'pre-line',
            textAlign: 'center'
        },
    },
    infoHeaderBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '13px',
        '& span': {
            cursor: 'pointer',
            color: '#FFF',
            margin: '0 10px',
            font: 'normal normal normal 13px/13px VodafoneRg',
            textDecoration: 'underline',
            minWidth: '80px'
        }
    },
    infoHeaderBoxDate: {
        borderRadius: '2px',
        backgroundColor: '#e60000',
        '& h3': {
            color: '#FFF',
            margin: '0 10px',
            font: 'normal normal bold 18px/20px VodafoneRg',
            minWidth: '120px',
            textAlign: 'center'
        }
    },
    content: {
        position: 'relative',
        height: '320px',
        background: '0% 0% no-repeat padding-box',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        margin: '0px 0px 13px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '12px',
        paddingRight: '12px'
    },
    footer: {
        display: 'flex',
        flexDirection: 'column'
    },
    row: {
        display: 'flex',
    },
    errorBox: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    infoBox: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        right: 0,
        left: 0,
        '& span': {
            width: '80%',
            font: 'normal normal normal 21px/25px VodafoneRg',
            color: '#FFFFFF',
            marginTop: '10px',
            textAlign: 'center'
        }
    },
    dashBox: {
        height: '298px',
        flex: 1,
        '& img': {
            height: '100%',
            width: '100%',
            objectFit: 'contain'
        }
    }
}))
