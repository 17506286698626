import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import "./style.css";




const LoadingPopup = () => {
    const classes = useStyles();
    return (

        <Box className={classes.root}>
            <div className="lds-ripple"><div></div><div></div></div>
        </Box>
    )
}

export default LoadingPopup;



const useStyles = makeStyles({
    root: {
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000
    }
});
