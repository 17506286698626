const Styles = theme => ({
    root: {
        height: '100vh',
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#FFF'
    },
    contentContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'Column',
        justifyContent: 'space-between',
    },
    toolbarContainer: {
        width: '100%',
        height: 'auto',
    },
    breadcrumbsContainer: {
        width: 'calc(100% - 40px)',
        height: 'auto',
        padding: '0px 60px',
    },
    viewContainer: {
        // width: 'calc(100% - 40px)',
        height: '100%',
        display: 'flex',
        // padding: '40px 60px',
        [theme.breakpoints.between('0', '600')]: {
            width: 'calc(100% - 25)',
            padding: '25px',
        },
        justifyContent: 'center',
        alignItems: 'flex-start',
        overflowY: 'scroll',
    },
})

export default Styles;