import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useOAuth } from '../../context/oauth';



const OAuthRedirector = () => {


    const navigate = useNavigate();


    const { oAuthRerirect } = useOAuth();



    useEffect(() => {
        switch (oAuthRerirect) {
            case 'backoffice':
                navigate('/bo')
                break;
            
            case 'app':
                navigate('/')
                break;
        
            default:
                navigate('/')
                break;
        }
        
    }, [oAuthRerirect])



    return (
        <></>
    );
}

export default OAuthRedirector;