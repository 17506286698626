import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const Button = ({
    disabled,
    label,
    styles,
    onClick
}) => {

    const classes = useStyles();

    return (
        <Box className={classes.root} onClick={() => { 
            if (!disabled) {
                onClick()
            }
        } } style={styles}>
            {label}
        </Box>
    )
}

export default Button;



const useStyles = makeStyles({
    root: {
        cursor: 'pointer',
        width: '100px',
        height: '52px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto 8px',
        backgroundColor: '#e60000',
        color: '#FFFFFF',
        textAlign: 'center',
        font: 'normal normal normal 18px/24px VodafoneRg',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: '#5e2750'
        }
    }
});
