import React, {
  createContext,
  useState,
  useContext
} from "react";

const ConfirmContext = createContext(null);

let resolveCallback;

const ConfirmProvider = (props) => {
  const [show, setShow] = useState(false);
  const [text, setTex] = useState("");
  const [title, setTitle] = useState("");

  const onConfirm = () => {
    setTitle("");
    setTex("");
    setShow(false);
    resolveCallback(true);
  };

  const onCancel = () => {
    setTitle("");
    setTex("");
    setShow(false);
    resolveCallback(false);
  };

  const confirm = (title, text) => {
    setTitle(title);
    setTex(text);
    setShow(true);
    return new Promise((res, rej) => {
      resolveCallback = res;
    });
  };

  return (
    <ConfirmContext.Provider
      value={{
        title,
        text,

        show,
        confirm,
        onConfirm,
        onCancel,
      }}
    >
      {props.children}
    </ConfirmContext.Provider>
  );
};

function useConfirm() {
  const context = useContext(ConfirmContext);
  if (!context) {
    throw new Error("useApp must be used within an AppProvider.");
  }
  return context;
}

export { ConfirmProvider, useConfirm };
