import { height } from "@mui/system";

const Styles = theme => ({
    root: {
        display: "flex",
        flexDire: "column",
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        overflow: "hidden"
    },
    feed: {
        flex: 1,
    },
    footer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: "60px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: "gray"
    },
    button: {
        flex: 1,
        padding: "15px",
        textAlign: "center",
        color: "#ffffff"
    }
})

export default Styles;