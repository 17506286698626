const Styles = theme => ({
    form: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    columnContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.between('0', '600')]: {
            flexDirection: 'column',
        },
    },
    column: {
        flex: '1',
        minWidth: '300px',
        [theme.breakpoints.between('0', '600')]: {
            minWidth: 'auto',
        },
        padding: '0px 7px',
    },
    paper:{
        display: 'flex',
        flexDirection: 'column',
        padding: '15px',
        margin: "40px 0px"
    },
    userPhoto: {
        width: '200px',
        height: '200px',
        fontSize: '80px',
        margin: '0px 20px 20px 20px'
    },
    userName: {
        ...theme.typography.title_h2,
    },
    userRole: {
        padding: '4px 0px',
        ...theme.typography.subtitle_h1,
    },
    icons: {
        fontSize: '19px',
        marginRight: '10px',
        color: '#B0191A'
    },
    button: {
        margin: '25px 0px 0px auto !important',
    },
    progress: {
        color: '#B0191A'
    },
    textArea: {
        "& .MuiOutlinedInput-root": {
            width: '100% !important',
            height: '100% !important',
            alignItems: 'flex-start'
        },
    },
})

export default Styles;