const Styles = theme => ({
    root: {
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
        height: '64px',
        display: 'flex',
        justifyContent: 'center',
    },
    innerBox: {
        maxWidth: '1280px',
        display: 'flex',
        justifyContent: 'space-between',
        flexGrow: 1
    },
    logoBox: {
        width: '147px',
        display: 'flex',
        alignItems: 'center'
    },
    rightActions: {
        width: 'auto',
        height: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    avatar: {
        height: '24px',
        width: '24px',
        marginLeft: '40px',
    },
    buttonsBox: {
        marginLeft: '10px',
        '& span': {
            cursor: 'pointer',
            fontFamily: 'Open Sans',
            fontSize: '16px',
            lineHeight: 1.25
        }
    }
})

export default Styles;