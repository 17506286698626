import React from "react";
import { useTranslation } from 'react-i18next';

//Material
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@material-ui/core';

import { useConfirm } from "../../../context/Confirm";




const ConfirmDialog = () => {

    const { t } = useTranslation();

    const classes = useStyles();


    const { onConfirm, onCancel, show, text, title } = useConfirm();


      const handleConfirm = () => {
        onConfirm()
      };

      const handleClose = () => {
        onCancel();
      };


    return (
      <Dialog
        maxWidth="sm"
        fullWidth
        open={show}
        onClose={handleClose}
        aria-labelledby="confirm-dialog-title"
      >
        <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            {t("cancel")}
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="outlined">
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
    );
}

export default ConfirmDialog;



const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));
