import palette from '../palette';


const MuiDialog = {
  paper: {
    padding: 0,
    backgroundColor: 'transparent',
    alignItems: 'stretch',
    color: palette.white,
      "& .MuiDialogTitle-root": {
        color: palette.white,
        backgroundColor: 'transparent',
        "& h2": {
          color: palette.white,
          margin: '0 20px'
        },
      },
      "& .MuiDialogContent-root": {
        margin: '20px',
        backgroundColor: 'transparent',
        color: palette.white,
      },
      "& .MuiDialogActions-root": {
        padding: '20px !important',
        marginLeft: 'auto',
        backgroundColor: 'transparent',
        color: palette.white
      }
    }
};

export default MuiDialog;

