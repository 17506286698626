
const MuiDataGrid = {
  columnHeader: {
      backgroundColor: '#000',
      color: "#FFFFFF",
    },
    columnHeaderTitle: {
      fontSize: '14px'
    }
};

export default MuiDataGrid;
