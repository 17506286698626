/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listUser = /* GraphQL */ `
  query ListUser {
    listUser {
      items {
        sub
        username
        enabled
        status
        createAt
        given_name
        family_name
        name
        picture
        email
        groups
      }
    }
  }
`;
export const listGroup = /* GraphQL */ `
  query ListGroup {
    listGroup {
      items {
        groupName
        description
        userGroupId
      }
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($input: GetUserInput) {
    getUser(input: $input) {
      sub
      username
      enabled
      status
      createAt
      given_name
      family_name
      name
      picture
      email
      groups
    }
  }
`;
export const getChannel = /* GraphQL */ `
  query GetChannel($input: GetChannelInput) {
    getChannel(input: $input) {
      errorMessage
      errorCode
      channel {
        name
        arn
      }
    }
  }
`;
export const getAnonymousUser = /* GraphQL */ `
  query GetAnonymousUser {
    getAnonymousUser {
      username
      password
    }
  }
`;
export const getPositionContents = /* GraphQL */ `
  query GetPositionContents($input: GetPositionContentsInput) {
    getPositionContents(input: $input) {
      errorMessage
      errorCode
      positionContents {
        position
        startDisplayTimestamp
        endDisplayTimestamp
        arn
        name
        status
      }
    }
  }
`;
export const getQueueContents = /* GraphQL */ `
  query GetQueueContents($input: GetQueueContentsInput) {
    getQueueContents(input: $input) {
      errorMessage
      errorCode
      queueContents {
        position
        startDisplayTimestamp
        endDisplayTimestamp
        arn
        name
        status
      }
    }
  }
`;
export const getEventStatus = /* GraphQL */ `
  query GetEventStatus {
    getEventStatus {
      errorMessage
      errorCode
      status
    }
  }
`;
export const getStreamingUserCount = /* GraphQL */ `
  query GetStreamingUserCount {
    getStreamingUserCount {
      errorMessage
      errorCode
      count
    }
  }
`;
export const listEventSchedulesCached = /* GraphQL */ `
  query ListEventSchedulesCached {
    listEventSchedulesCached {
      errorMessage
      errorCode
      items {
        id
        name
        description
        startTimestamp
        endTimestamp
        dates {
          startTimestamp
          endTimestamp
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const getBoModule = /* GraphQL */ `
  query GetBoModule($id: ID!) {
    getBOModule(id: $id) {
      id
      name
      description
      enabled
      order
      submodules {
        id
        featureId
        featureType
        name
        description
        enabled
        order
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBoModules = /* GraphQL */ `
  query ListBoModules(
    $filter: ModelBOModuleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBOModules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        enabled
        order
        submodules {
          id
          featureId
          featureType
          name
          description
          enabled
          order
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMediaGridContent = /* GraphQL */ `
  query GetMediaGridContent($PK: String!, $SK: String!) {
    getMediaGridContent(PK: $PK, SK: $SK) {
      PK
      SK
      status
      startDisplayTimestamp
      endDisplayTimestamp
      contentRef
      arn
      name
      disconnectTimestamp
      connectTimestamp
      updatedAt
      count
      creationTimestamp
      appearedTimestamp
      createdAt
    }
  }
`;
export const listMediaGridContents = /* GraphQL */ `
  query ListMediaGridContents(
    $PK: String
    $SK: ModelStringKeyConditionInput
    $filter: ModelMediaGridContentFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMediaGridContents(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        status
        startDisplayTimestamp
        endDisplayTimestamp
        contentRef
        arn
        name
        disconnectTimestamp
        connectTimestamp
        updatedAt
        count
        creationTimestamp
        appearedTimestamp
        createdAt
      }
      nextToken
    }
  }
`;
export const getLog = /* GraphQL */ `
  query GetLog($PK: String!, $SK: String!) {
    getLog(PK: $PK, SK: $SK) {
      PK
      SK
      timestamp
      description
      reference
      createdAt
      updatedAt
    }
  }
`;
export const listLogs = /* GraphQL */ `
  query ListLogs(
    $PK: String
    $SK: ModelStringKeyConditionInput
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLogs(
      PK: $PK
      SK: $SK
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        PK
        SK
        timestamp
        description
        reference
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const contentByStatus = /* GraphQL */ `
  query ContentByStatus(
    $PK: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMediaGridContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contentByStatus(
      PK: $PK
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        PK
        SK
        status
        startDisplayTimestamp
        endDisplayTimestamp
        contentRef
        arn
        name
        disconnectTimestamp
        connectTimestamp
        updatedAt
        count
        creationTimestamp
        appearedTimestamp
        createdAt
      }
      nextToken
    }
  }
`;
export const byReference = /* GraphQL */ `
  query ByReference(
    $reference: String
    $sortDirection: ModelSortDirection
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byReference(
      reference: $reference
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        PK
        SK
        timestamp
        description
        reference
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventSchedule = /* GraphQL */ `
  query GetEventSchedule($id: ID!) {
    getEventSchedule(id: $id) {
      id
      name
      description
      startTimestamp
      endTimestamp
      dates {
        startTimestamp
        endTimestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEventSchedules = /* GraphQL */ `
  query ListEventSchedules(
    $filter: ModelEventScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        startTimestamp
        endTimestamp
        dates {
          startTimestamp
          endTimestamp
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
