import AxiosInstance from "./../axios";
import { API, graphqlOperation } from "aws-amplify";
import {
    getAnonymousUser, getChannel, getPositionContents, getEventStatus, listEventSchedulesCached, getStreamingUserCount,
    listEventSchedules, getEventSchedule, getQueueContents
} from '../../../graphql/queries';
import {
    updateChannelStatus, removeContentFromGrid, startEvent, stopEvent, extendContentFromGrid,
    startStreamingEvent, reportQrCodeUsed, deleteEventSchedule, createEventSchedule, updateEventSchedule,
    reportEnteredApp, removeFromQueue
} from '../../../graphql/mutations';



// Get an available channel
export const GetChannel = async (type, clientId) => {
    return await API.graphql({
        query: getChannel,
        variables: { input: { clientId: clientId } },
        operationName: 'getChannel',
        authMode: type
    });
};

// Get a list of channels for the feed channel position.
export const GetPositionContents = async (positionList, isMaster) => {
    return await API.graphql(graphqlOperation(getPositionContents, { input: { positions: positionList, isMaster: isMaster } }));
};

// Get credentials for an anonymous user 
export const GetAnonymousUser = async () => {
    return await API.graphql({ query: getAnonymousUser, operationName: 'getAnonymousUser', authMode: 'AWS_IAM' });
};

// Update status of a channel
export const UpdateChannelStatus = async (data) => {
    return await API.graphql(graphqlOperation(updateChannelStatus, { input: data }));
}

// Get event status
export const GetEventStatus = async (type) => {
    return await API.graphql({ query: getEventStatus, operationName: 'getEventStatus', authMode: type });
};

export const StartEvent = async () => {
    return await API.graphql(graphqlOperation(startEvent));
};

export const StopEvent = async () => {
    return await API.graphql(graphqlOperation(stopEvent));
};

export const StartStreamingEvent = async () => {
    return await API.graphql(graphqlOperation(startStreamingEvent));
};

export const GetStreamingUserCount = async () => {
    return await API.graphql(graphqlOperation(getStreamingUserCount));
};

export const RemoveContentFromGrid = async (position,channelName) => {
    return await API.graphql(graphqlOperation(removeContentFromGrid, { input: { position: position, name: channelName } }));
};

export const RemoveFromQueue = async (queueName) => {
    return await API.graphql(graphqlOperation(removeFromQueue, { input: { name: queueName } }));
};




export const ListEventSchedules = async (type) => {
    return await API.graphql({ query: listEventSchedulesCached, operationName: 'listEventSchedules', authMode: type });
};

export const GetEventSchedules = async (id) => {
    return await API.graphql(graphqlOperation(getEventSchedule, { id: id }));
};

export const EventSchedulesList = async () => {
    return await API.graphql(graphqlOperation(listEventSchedules));
};

export const DeleteEventSchedule = async (id) => {
    return await API.graphql(graphqlOperation(deleteEventSchedule, { input: { id: id } }));
};


export const CreateEventSchedule = async (data) => {
    return await API.graphql(graphqlOperation(createEventSchedule, { input: data }));
};

export const UdpateEventSchedule = async (data) => {
    return await API.graphql(graphqlOperation(updateEventSchedule, { input: data }));
};


export const ExtendContentFromGrid = async (position) => {
    return await API.graphql(graphqlOperation(extendContentFromGrid, { input: { position: position } }));
};

export const ReportQrCodeUsed = async (type) => {
    return await API.graphql({ query: reportQrCodeUsed, operationName: 'reportQrCodeUsed', authMode: type });
};


export const ReportEnteredApp = async (data, type) => {
    return await API.graphql({ query: reportEnteredApp, operationName: 'reportEnteredApp', authMode: type, variables: { input: data } });
}


// Get configuration
export const GetCalendarEvent = async () => new Promise((result, error) => {
    fetch(`/config/rir_calendar_event.json`)
        .then((r) => r.json())
        .then((data) => {
            result(data)
        }).catch(() => error(null))
})


export const GetEventStatusCloudfront = async () =>
    await AxiosInstance.get(`${process.env.REACT_APP_CF_BASE_PATH}/event`);


export const ListEventSchedulesCloudfront = async () => 
 await AxiosInstance.get(`${process.env.REACT_APP_CF_BASE_PATH}/schedule `);


export const GetQueueContents = async (data) => {
    return await API.graphql({ query: getQueueContents, operationName: 'getQueueContents', variables: { input: data } });
};
