import React, { createContext } from 'react';


import { GroupAndUserService } from './GroupAndUsers/group-and-user-service';
import { VideoStreamService } from './VideoStream/video-stream-service';


export const ServiceContext = createContext();

const ServiceContextProvider = (props) => {

    const GroupAndUser = GroupAndUserService();
    const VideoStream = VideoStreamService();

    return (
        <ServiceContext.Provider value={{ GroupAndUser, VideoStream }}>
            {props.children}
        </ServiceContext.Provider>
    )
}

export default ServiceContextProvider;