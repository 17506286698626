import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, Avatar } from '@material-ui/core';

import { LanguageSelector } from '../../../components';
import MenuUser from '../MenuUser'

import Assets from '../../../assets';

import Styles from './style';

import { AuthContext } from '../../../context/auth/auth-context';
import { useApp } from '../../../context/app';


const useStyles = makeStyles(Styles);

const AppBar = () => {

    const classes = useStyles();

    const { isAuthenticated } = useContext(AuthContext)

    const { setOpenPopupLogin } = useApp();


    const onLoginApp = () => {
        setOpenPopupLogin(true)
    }

    return (
        <Toolbar className={classes.root}>
            <div className={classes.innerBox}>
                <div className={classes.logoBox}>
                    <img src={Assets.Images.cyc_logo_full} alt="logo" />
                </div>
                <div className={classes.rightActions}>
                    <LanguageSelector />
                    {!isAuthenticated ? (
                        <React.Fragment>
                            <Avatar className={classes.avatar}/>
                            <div className={classes.buttonsBox}>
                                <span onClick={onLoginApp}>Login</span>
                                {/*
                            <span onClick={onLoginApp}>&nbsp;/&nbsp;</span>
                            <span onClick={onLoginApp}>Register</span>
                        */}
                            </div>
                        </React.Fragment>

                    ) : <MenuUser /> }
                </div>
            </div>
        </Toolbar>
    );
}

export default AppBar;