
import i18n from "i18next";
import moment from "moment";
import 'moment/locale/pt';

export const getLinksSplited = (path) => {
  let links = path.split("/");
  links[0] = "home";
  return links;
};



export function getTime(timestamp) {
  return moment.unix(timestamp).format("H:mm")
}

// { isPeriod: false, startDate: Date(), endDate: Date() }
export const getDate = (date) => {
  let startDate = moment.unix(date?.startDate).format("DD MMMM YYYY");

  if (!date?.isPeriod) {
    let month = startDate.split(' ')[1].charAt(0).toUpperCase() + startDate.split(' ')[1].slice(1);
    let result = startDate.split(' ')[0] + " " + month + " " + startDate.split(' ')[2];
    return result;
  }

  let endDate = moment.unix(date?.endDate).format("DD MMMM YYYY");
  let startJson = { day: startDate.split(' ')[0], month: startDate.split(' ')[1].charAt(0).toUpperCase() + startDate.split(' ')[1].slice(1), year: startDate.split(' ')[2] }
  let endJson = { day: endDate.split(' ')[0], month: endDate.split(' ')[1].charAt(0).toUpperCase() + endDate.split(' ')[1].slice(1), year: endDate.split(' ')[2] }

  if (startJson.month === endJson.month) {
    return `${startJson.day}-${endJson.day} ${startJson.month} de ${startJson.year}`;
  } else {
    return `${startJson.day} ${startJson.month.substring(0, 3)} - ${endJson.day} ${endJson.month.substring(0, 3)} de ${endJson.year}`;
  }

}

export function getDateStringFormat(timestamp, month = false) {
  moment.locale(i18n.language);
  let startDate = moment.unix(timestamp).format("DD MMMM YYYY");
  let _month = startDate.split(' ')[1].charAt(0).toUpperCase() + startDate.split(' ')[1].slice(1);
  switch (i18n.language) {
    case 'pt':
      return month ? startDate.split(' ')[0] + " " + _month : startDate.split(' ')[0] + " " + _month + " " + startDate.split(' ')[2];
    case 'en':
      return month ? _month + "  " + startDate.split(' ')[0] : _month + " " + startDate.split(' ')[0] + ", " + startDate.split(' ')[2];
    default:
      return
  }
}



export function calculateDistance(lat1, lon1, lat2, lon2) {
  lon1 = lon1 * Math.PI / 180;
  lon2 = lon2 * Math.PI / 180;
  lat1 = lat1 * Math.PI / 180;
  lat2 = lat2 * Math.PI / 180;

  // Haversine formula
  let dlon = lon2 - lon1;
  let dlat = lat2 - lat1;
  let a = Math.pow(Math.sin(dlat / 2), 2)
    + Math.cos(lat1) * Math.cos(lat2)
    * Math.pow(Math.sin(dlon / 2), 2);

  let c = 2 * Math.asin(Math.sqrt(a));

  // Radius of earth in kilometers. Use 3956
  // for miles
  let r = 6371;

  const result = (c * r) * 1000;

  return result
}