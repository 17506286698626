import React, { useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, TextField, InputAdornment, IconButton, Button, CircularProgress, Typography } from '@material-ui/core';
import { LockRounded, Visibility, VisibilityOff } from '@material-ui/icons';

import { useNavigate, useLocation } from 'react-router-dom';


import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { AuthContext } from '../../../context/auth/auth-context.js';
import { LanguageSelector } from '../../../components';
import { useNotifications } from '../../../components/Shared/NotificationSnackbar';
import Assets from '../../../assets';
import Styles from './style';

const useStyles = makeStyles(Styles);

const NewPasswordRequired = () => {

    const { completeNewPassword } = useContext(AuthContext);
    const { triggerNotification } = useNotifications();
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [values, setValues] = useState({ showPassword: false, showConfirmPassword: false });

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        onSubmit: async values => {
            try {
                if (values.password !== values.confirmPassword) {
                    triggerNotification("warning", "notifications-default-MatchPassword-message", "notifications-default-MatchPassword-title")
                    return;
                }
                await completeNewPassword(values.confirmPassword);
                let from = location.state?.from?.pathname || "/";
                navigate(from, { replace: true });
            } catch (error) {
                throw error;
            }
        },
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleClickShowConfirmPassword = () => {
        setValues({ ...values, showConfirmPassword: !values.showConfirmPassword });
    };

    return (
        <Container className={classes.main}>
            <Container className={classes.header}>
                  <div className={classes.logo}>
                    <img src={Assets.Images.cyc_logo_full} alt="logo" />
                </div>
                <LanguageSelector />
            </Container>
            <Container className={classes.content}>
               <div className={classes.contentHeader}>
                    <div className={classes.titleBox}>
                        <Typography variant="h1">
                            Template
                        </Typography>
                    </div>
                  
                    <div className={classes.bulletBox}>
                        <img src={Assets.Images.cy_bullets} alt="bullets" />
                    </div>
                  
                </div>
                <Paper className={classes.paper} elevation={0}>
                    <div className={classes.title}>{t('newpassword-text-title')}</div>
                    <div className={classes.subtitle}>{t('newpassword-text-subtitle')}</div>
                    <br />
                    <form onSubmit={formik.handleSubmit} className={classes.form}>
                        <TextField
                            id="password"
                            type={values.showPassword ? 'text' : 'password'}
                            placeholder={t('newpassword-input-password')}
                            variant="outlined"
                            InputProps={{
                                startAdornment: <LockRounded className={classes.icons} />,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end">
                                        {values.showPassword ? <Visibility className={classes.icons} /> : <VisibilityOff className={classes.icons} />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                            onChange={formik.handleChange}
                            error={formik.errors.password && formik.touched.password}
                            helperText={(formik.errors.password && formik.touched.password) && formik.errors.password}
                            required
                        />
                        <TextField
                            id="confirmPassword"
                            type={values.showConfirmPassword ? 'text' : 'password'}
                            placeholder={t('newpassword-input-confirm-password')}
                            variant="outlined"
                            InputProps={{
                                startAdornment: <LockRounded className={classes.icons} />,
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowConfirmPassword}
                                        edge="end">
                                        {values.showConfirmPassword ? <Visibility className={classes.icons} /> : <VisibilityOff className={classes.icons} />}
                                    </IconButton>
                                </InputAdornment>
                            }}
                            onChange={formik.handleChange}
                            error={formik.errors.confirmPassword && formik.touched.confirmPassword}
                            helperText={(formik.errors.confirmPassword && formik.touched.confirmPassword) && formik.errors.confirmPassword}
                            required
                        />
                        <Button type='submit' variant="contained" disableElevation disabled={formik.isSubmitting}>{
                            formik.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('newpassword-btn-text')
                        }</Button>
                    </form>
                </Paper>
            </Container>
            <Container className={classes.footer} maxWidth="xl">
                <span className={classes.cycText}>CYCLOID Technology and Consulting, Lda.</span>
                <span className={classes.cycPrivacy}>© All rights reserved.&nbsp;<a href="https://www.cycloid.pt/pdf/Cycloid_Privacy%20Policy.pdf" className={classes.anchor} target="_blank">{t('signin-footer-text-subtext-2')}</a></span>
                <span className={classes.cycWebsite}>www.cycloid.pt</span>
            </Container>
        </Container>
    );
}

export default NewPasswordRequired;