
const title_color = '#474747';
const subtitle_color = '#C2C2C2';
const label_color = '#C2C2C2';
const anchor_color = '#B0191A';  // SECUNDARY COLOR

const typography = {
      h1: {
            color: '#000000',
            fontFamily: 'Open Sans',
            fontSize: '38px',
            lineHeight: 1.21
        },
        h2: {
            fontFamily: 'Open Sans',
            fontWeight: 600,
            fontSize: '23px',
            letterSpacing: '-0.24px',
            lineHeight: '42px'
        },
    title_h1: {
        fontSize: '40px',
        fontWeight: 'bold',
        color: title_color,
    },
    title_h1_reg: {
        fontSize: '40px',
        fontWeight: 'regular',
        color: title_color,
    },
    title_h2: {
        fontSize: '28px',
        fontWeight: 'bold',
        color: title_color,
    },
    subtitle_h1: {
        fontSize: '20px',
        fontWeight: 'regular',
        color: subtitle_color,
    },
    subtitle: {
        fontSize: '16px',
        fontWeight: 'regular',
        color: subtitle_color,
    },
    body: {},
    label: {
        fontSize: '13px',
        fontWeight: 'regular',
        color: label_color,
    },
    anchor: {
        fontSize: '13px',
        fontWeight: 'regular',
        color: anchor_color,
    },
}

export default typography;