const Styles = theme => ({
    root: {
        width: 'auto',
        height: '100%',
        overflow: 'hidden'
    },
    rootPersistance: {
        width: '0px',
        padding: '0px',
    },
    rootPermanent: {
        padding: '0px 10px',
    },
    drawer: {
        width: '240px',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        [theme.breakpoints.between('0', '600')]: {
            '& .MuiDrawer-paper': {
                boxShadow: 'none'
            }
        }
    },
    drawerOpen: {
        width: '240px',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '57px',
    },
    list_root: {
        width: '100%',
        height: '100%',
        padding: '20px 0px',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
    list_item_root: {
        margin: '7px 0px',
        borderRadius: '10px',
        '&:hover': {
            backgroundColor: '#B0191A',
            '& .MuiListItemIcon-root': {
                color: 'white',
            },
            '& .MuiListItemText-root': {
                color: 'white',
            }
        },
        '& .MuiListItemIcon-root': {
            color: '#C2C2C2',
        },
        '& .MuiListItemText-root': {
            color: '#C2C2C2',
        }
    },
    list_item_root_nested: {
        margin: '7px 0px',
        borderRadius: '10px',
        '&:hover': {
            backgroundColor: '#B0191A',
            '& .MuiListItemIcon-root': {
                color: 'white',
            },
            '& .MuiListItemText-root': {
                color: 'white',
            },
            '& .MuiSvgIcon-root': {
                fontSize: '1.2rem',
            }
        },
        '& .MuiListItemIcon-root': {
            justifyContent: 'center',
            alignItems: 'center',
            color: '#C2C2C2',
        },
        '& .MuiListItemText-root': {
            color: '#C2C2C2',
        },
        '& .MuiSvgIcon-root': {
            fontSize: '0.6rem',
            transition: 'all 0.2s linear'
        }
    }
})

export default Styles;