import React from 'react';
import { Outlet } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import SignInPopup from '../../screens/App/SignInPopup';
import RecoverPasswordPopup from '../../screens/App/RecoverPasswordPopup';
import NewPasswordRequiredPopup from '../../screens/App/NewPasswordRequiredPopup';
import AppBar from '../../components/App/AppBar';


import Styles from './style';

import { useApp } from '../../context/app';


const useStyles = makeStyles(Styles);

const PrivateLayoutApp = () => {

    const classes = useStyles();

    const { openPopupLogin, openPopupRecoverPassword, openPopupPasswordRequired } = useApp();



    return (
        <React.Fragment>
            <div className={classes.root}>
                <div className={classes.contentContainer}>
                    <div className={classes.toolbarContainer}>
                        <AppBar />
                    </div>
                    <div className={classes.viewContainer}>
                        <Outlet />
                    </div>
                </div>
            </div>
            {openPopupLogin && <SignInPopup open={openPopupLogin} />}
            {openPopupRecoverPassword && <RecoverPasswordPopup open={openPopupRecoverPassword} />}
            {openPopupPasswordRequired && <NewPasswordRequiredPopup open={openPopupPasswordRequired} />}

        </React.Fragment>
    );
}

export default PrivateLayoutApp; 