import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Paper, TextField, Button, CircularProgress, Dialog } from '@material-ui/core';
import { EmailRounded } from '@material-ui/icons';

import { useNavigate, } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { AuthContext } from '../../../context/auth/auth-context.js';
import { useNotifications } from '../../../components/Shared/NotificationSnackbar';
import Styles from './style';

import { useApp } from '../../../context/app';


const useStyles = makeStyles(Styles);

const RecoverPasswordPopup = ({ open}) => {

    const { forgotPassword } = useContext(AuthContext);

    const { setOpenPopupRecoverPassword, setOpenPopupLogin } = useApp();


    const { triggerNotification } = useNotifications();
    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        onSubmit: async values => {
            try {
                const response = await forgotPassword(values.email);
                if (response) {
                    triggerNotification("success", "notifications-recoverpassword-emailsent-message", "notifications-recoverpassword-emailsent-title");
                    navigate(`/auth/recover-password/confirm?user=${values.email}`);
                }
            } catch (error) {
                throw error;
            }
        },
    });

    return (

        <Dialog
            className={classes.dialog}
            maxWidth="md"
            disableBackdropClick={false}
            onClose={() => setOpenPopupRecoverPassword(false)}
            open={open}
            aria-labelledby="popup-info-title"
            aria-describedby="popup-info-description"
        >

            <Paper className={classes.paper} elevation={0}>
                <div className={classes.title}>{t('recoverpass-text-title')}</div>
                <div className={classes.subtitle}>{t('recoverpass-text-subtitle')}</div>
                <br />
                <form onSubmit={formik.handleSubmit} className={classes.form}>
                    <TextField
                        id="email"
                        type='email'
                        placeholder={t('recoverpass-input-email')}
                        variant="outlined"
                        InputProps={{ startAdornment: <EmailRounded className={classes.icons} /> }}
                        onChange={formik.handleChange}
                        required
                    />
                    <Button type='submit' variant="contained" disableElevation disabled={formik.isSubmitting}>{
                        formik.isSubmitting ? <CircularProgress size={24} className={classes.progress} /> : t('recoverpass-btn-text')
                    }</Button>
                </form>
                <span className={classes.subtext}>{t('recoverpass-text-subtext-1')}&nbsp;&nbsp;
                    <span onClick={() => {
                        setOpenPopupRecoverPassword(false)
                        setOpenPopupLogin(true)
                    }} className={classes.anchor}>
                        {t('recoverpass-text-subtext-2')}
                    </span>
                </span>    
            </Paper>

        </Dialog>

    );
}

export default RecoverPasswordPopup;

