import React from "react";
import { useTranslation } from "react-i18next";

//Material
import { makeStyles } from"@material-ui/core/styles";
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle  } from '@material-ui/lab';
import { useAlert } from "../../../context/Alert";


const AlertDialog = () => {
  const { t } = useTranslation();

  const classes = useStyles();

  const { type, showAlert, setShowAlert, msg } = useAlert();


  if (type === 0)
    
    return (
      <Snackbar
        className={classes.root}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={showAlert}
        autoHideDuration={6000}
        onClose={() => setShowAlert(false)}
      >
        <Alert className={classes.alert} severity="error" variant="filled">
          <AlertTitle style={{ color: '#FFF' }}>{t("error")}</AlertTitle>
          {msg}
        </Alert>
      </Snackbar>
    );

  return (
    <Snackbar
      className={classes.root}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      open={showAlert}
      autoHideDuration={10006000000}
      onClose={() => setShowAlert(true)}
    >
      <Alert className={classes.alert} severity="success" variant="filled">
        <AlertTitle style={{color: '#FFF'}}>{t("success")}</AlertTitle>
        {msg}
      </Alert>
    </Snackbar>
  );
};

export default AlertDialog;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "220px",
  },
  alert: {
    color: "#FFF",
    whiteSpace: 'pre-line'
  },
}));
