import React from 'react';
import localforage from 'localforage';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useVideowall } from '../../../context/Videowall';
import Button from '../Button';



const LANGS_CHANGE = {
    pt: 'English',
    en: 'Português'
}


const Footer = () => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { setShowTerms } = useVideowall()


    const onChangeLang = async () => {
        const lang = i18n.language;
        if (lang === 'pt') {
            i18next.changeLanguage('en');
            await localforage.setItem('lang', 'en')
        } else {
            i18next.changeLanguage('pt');
            await localforage.setItem('lang', 'pt')
        }
    }


    const termsHandler = () => {
        setShowTerms(true)
    }



    return (
        <div className={classes.root}>
            <div className={classes.row}>
                <Button
                    key="laguage"
                    label={LANGS_CHANGE[i18n?.language]}
                    onClick={() => onChangeLang()}
                    styles={{ backgroundColor: '#64050a', border: '1px solid #64050a', color: '#FFF', fontSize: '13px', lineHeight: 1 }} 
                />
                <Button
                    key="terms"
                    label={t('videowall-app-footer-terms')}
                    onClick={() => termsHandler()}
                    styles={{ backgroundColor: '#64050a', border: '1px solid #64050a', color: '#FFF', fontSize: '13px', lineHeight: 1 }} 
                />
            </div>
            <div className={classes.copyRight}>
                <span>© Vodafone 2023</span>
            </div>
        </div>
    )
}

export default Footer


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '12px'
    },
    row: {
        display: 'flex',
        margin: '3px auto'
    },
    copyRight: {
        marginTop: '12px',
        '& span': {
            font: 'normal normal normal 11px VodafoneRg',
            margin: 0,
            color: '#FFF'
        }
    }
}))
