import cyc_logo_full from './logo cycloid.svg';
import cyc_logo from './Cycloid_RGB_Icon.svg';
import cyc_logo_parcial from './Cycloid_RGB_Positive.svg';
import empty_image from './empty_image.jpg';
import error_image from './error_image.jpg';
import doc_thumbnail from './doc_type_thumbnail.jpg';
import image_thumbnail from './image_type_thumbnail.jpg';
import cy_bullets from './cy bullets decor.svg'
import todoImage from './todo-image.jpg';
import cycloid_pattern from './cycloid_pattern.png';
import cycloid_grey_icon from './cycloid_grey_icon.png';
import rir_background from './rir_background.png';
import vdf_logo from './vdf_logo.svg';
import vdf_poweredby from './vdf_poweredby.svg';
import vdf_event from './vdf_event.png';
import videowall_logo_vodafone from './videowall/logo_vodafone.svg';
import videowall_logo_vodafone_start from './videowall/logo_vodafone_start.svg';
import videowall_logo_poweredby5g_start from './videowall/logo_poweredby5g_start.svg';
import videowall_logo_poweredby5g from './videowall/logo_poweredby5g.svg';
import videowall_background from './videowall/background.png';
import videowall_stage_cam_break_big from './videowall/stage-cam-break-big.png';
import videowall_stage_cam_big from './videowall/stage-cam-big.png';
import videowall_stage_cam from './videowall/stage-cam.png';
import videowall_ico_camera from './videowall/ico_camera.svg';
import videowall_ico_refresh from './videowall/ico_refresh.svg';
import videowall_dashed_line from './videowall/dashed_line.png';
import videowall_ico_location from './videowall/ico_location.svg';
import videowall_stage_cam_start_all from './videowall/logo_stagecam_startall.png';
import rir_qrcode from './qrcode-vodafonestagecam.png';
import background_vdf_coura from './videowall/background_vdf_coura.png';
import background_vdf_coura_1248x832 from './videowall/background_vdf_coura_1248x832.png';
import stage_cam_big from './videowall/stage-cam.png';




const Images = {
    cyc_logo_full: cyc_logo_full,
    cyc_logo: cyc_logo,
    cyc_logo_parcial: cyc_logo_parcial,
    empty: empty_image,
    error_image: error_image,
    doc_thumbnail: doc_thumbnail,
    image_thumbnail: image_thumbnail,
    cy_bullets: cy_bullets,
    todoImage: todoImage,
    cycloid_pattern: cycloid_pattern,
    cycloid_grey_icon: cycloid_grey_icon,
    rir_background: rir_background,
    vdf_poweredby: vdf_poweredby,
    vdf_logo: vdf_logo,
    vdf_event: vdf_event,
    videowall_logo_vodafone: videowall_logo_vodafone,
    videowall_logo_vodafone_start: videowall_logo_vodafone_start,
    videowall_logo_poweredby5g: videowall_logo_poweredby5g,
    videowall_logo_poweredby5g_start: videowall_logo_poweredby5g_start,
    videowall_background: videowall_background,
    videowall_stage_cam_break_big: videowall_stage_cam_break_big,
    videowall_stage_cam_big: videowall_stage_cam_big,
    videowall_stage_cam: videowall_stage_cam,
    videowall_ico_camera: videowall_ico_camera,
    videowall_ico_refresh: videowall_ico_refresh,
    videowall_dashed_line: videowall_dashed_line,
    videowall_ico_location: videowall_ico_location,
    videowall_qrcode: rir_qrcode,
    videowall_stage_cam_start_all: videowall_stage_cam_start_all,
    background_vdf_coura: background_vdf_coura,
    background_vdf_coura_1248x832: background_vdf_coura_1248x832,
    stage_cam_big: stage_cam_big
}

export default Images;
