import React, { createContext, useState, useContext } from 'react';

const AppContext = createContext()

const AppProvider = ({ children }) => {

    const [appLoading, setAppLoading] = useState(true)
    const [openPopupLogin, setOpenPopupLogin] = useState(false)
    const [openPopupRecoverPassword, setOpenPopupRecoverPassword] = useState(false)
    const [openPopupPasswordRequired, setOpenPopupPasswordRequired] = useState(false)


    return (
        <AppContext.Provider
            value={{
                appLoading, setAppLoading,
                openPopupLogin, setOpenPopupLogin,
                openPopupRecoverPassword, setOpenPopupRecoverPassword,
                openPopupPasswordRequired, setOpenPopupPasswordRequired
            }}>
            {children}
        </AppContext.Provider>
    );

}

function useApp() {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useApp must be used within an AppProvider.');
    }
    return context;
}


export { AppProvider, useApp };
