const Styles = theme => ({
    root: {
        height: '100vh',
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // backgroundColor: '#FFF'
    },
    contentContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'Column',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    toolbarContainer: {
        width: '100%',
        height: 'auto',
    },
    viewContainer: {
        width: '1280px',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        overflowY: 'scroll'
    },
})

export default Styles;