const Styles = theme => ({
    root:{
        '&.MuiPaper-root': {
            flexDirection: 'row',
            alignItems: 'flex-start',
        },
        '&.MuiPaper-root .MuiAlert-message': {
            lineHeight: '0.9'
        }
    }
})

export default Styles;

