import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import AuthContextProvider from './context/auth/auth-context';
import ServiceContextProvider from './context/services/service-context';
import { OauthProvider } from './context/oauth';
import { AppProvider } from './context/app';
import { ConfirmProvider } from './context/Confirm';
import { AlertProvider } from './context/Alert';
import Sample from './theme/sample';
import Routes from './routes'
import ConfirmDialog from './components/Backoffice/ConfirmDialog';
import AlertDialog from './components/Backoffice/AlertDialog';
import Notifications from './components/Shared/NotificationSnackbar';


function App() {

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', installProp);
    window.addEventListener('appinstalled', (e) => {
        console.log(e)
    });
  }, []);



  const installProp = e => {
    e.preventDefault();
    e.prompt();
    e.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        // Aceitou
        window.removeEventListener('beforeinstallprompt', installProp)
      } else {
        // NAO ACEITOU
      }
    });

  }


  return (
    <Suspense fallback={<div></div>}>
      <OauthProvider>
        <AuthContextProvider>
          <AppProvider>
            <BrowserRouter>
              <ThemeProvider theme={Sample}>
                <ConfirmProvider>
                  <AlertProvider>
                    <ServiceContextProvider>
                      <Routes />
                      <ConfirmDialog />
                      <AlertDialog />
                      <Notifications />
                    </ServiceContextProvider>
                  </AlertProvider>
                </ConfirmProvider>
              </ThemeProvider>
            </BrowserRouter>
          </AppProvider>
        </AuthContextProvider>
      </OauthProvider>

    </Suspense>
  );
}

export default App;
