import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withStyles, makeStyles, Dialog, Slide, FormControlLabel, Checkbox } from '@material-ui/core';
import BackIcon from '@material-ui/icons/NavigateBefore';
import Button from '../Button';
import Assets from '../../../assets'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});




const TermsAndConditions = ({
    onAccept,
    onBack, // Close without accept
    onClose, // Close after accept
    hiddenButtons
}) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const [check1, setCheck1] = useState(false)
    const [check2, setCheck2] = useState(false)



    const handleAccept = (value) => {
        onAccept(value)
        if (!value) {
            onBack() 
        }
    }

    const backHandler = () => {
        if (hiddenButtons) {
            onClose()
        } else {
            onBack() 
        }
    }

    const label = (
        <span>
            {`${t('videowall-app-terms-check2_1')}`}
            <i
                style={{ font: 'normal normal bold 14px/18px VodafoneRg', color: '#e60000'}}
                onClick={() => { 
                    let url = ''
                    if (i18n?.language === 'pt') {
                        url = `https://vodafonestagecam.com/Regulamento_StageCam.pdf`

                    } else {
                        url = `https://vodafonestagecam.com/Regulation_StageCam_EN.pdf`

                    }
                    //console.log(url)
                    window.open(url, "_blank")
                }}
                >
                &nbsp;{`${t('videowall-app-terms-check-link')}`}&nbsp;
            </i>
            {`${t('videowall-app-terms-check2_2')}`}
  </span>
)

    return (
        <Dialog fullScreen open={true} TransitionComponent={Transition} className={classes.dialog}>
            <div className={classes.root}>
                <div className={classes.header} onClick={() => backHandler()}>
                    <BackIcon />
                    <span>{t('back')}</span>
                </div>
                <div className={classes.content}>
                    <h5>{t('videowall-app-terms-title')}</h5>
                    <p>{hiddenButtons ? t('videowall-app-terms-text-accepted') : t('videowall-app-terms-text') }</p>
                </div>
                {!hiddenButtons && (
                    <div className={classes.checkBox}>
                        <FormControlLabel
                            style={{ marginBottom: '10px' }}
                            className={classes.formControlLabel}
                            control={<CustomCheckbox checked={check1} onChange={(event) => {
                                setCheck1(event.target.checked)
                            }} name="checkedA" />
                            }
                            label={t('videowall-app-terms-check1')}
                        />
                        <FormControlLabel
                            style={{ marginBottom: '10px' }}
                            className={classes.formControlLabel}
                            control={<CustomCheckbox checked={check2} onChange={(event) => {
                                setCheck2(event.target.checked)
                            }} name="checkedB" />
                            }
                            label={label}
                        />
                    </div>
                )}
                {hiddenButtons && (
                    <div className={classes.checkBox}>
                        <p>{t('videowall-app-terms-check1')}</p>
                        <p>{label}</p>

                    </div>
                )}

               
                
                {!hiddenButtons && (
                    <div className={classes.footer}>
                        <Button key="decline" label={t('decline')} onClick={() => handleAccept(false)} />
                        <Button
                            disabled={(!check1 | !check2)}
                            key="accept"
                            label={t('accept')}
                            onClick={() => handleAccept(true)}
                            styles={{ backgroundColor: (!check1 | !check2) ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 1)', border: '1px solid #e60000', color: '#e60000'}} />
                    </div>
                )}
            </div>
        </Dialog>
    )
}

export default TermsAndConditions;


const useStyles = makeStyles(theme => ({
    dialog: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        backgroundImage: `url(${Assets.Images.videowall_background})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0) !important'
        }
    },
    root: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        height: '100%',
        padding: '18px',
        overflowY: 'auto'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            cursor: 'pointer',
            font: 'normal normal normal 16px/18px VodafoneRg'
        }
    },
    content: {
        margin: '18px 0px 24px',
        '& h5': {
            font: 'normal normal normal 32px/37px VodafoneRg',
            margin: '0 0 24px',
        },
        '& p': {
            font: 'normal normal normal 14px/18px VodafoneRg',
            margin: 0
        }
    },
    checkBox: {
        marginBottom: '34px',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiFormControlLabel-label': {
            font: 'normal normal normal 14px/18px VodafoneRg',
            paddingTop: '12px'
        },
        '& p': {
            font: 'normal normal normal 14px/18px VodafoneRg',
            margin: 0,
            marginBottom: '16px'
        }
    },
    footer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    formControlLabel: {
        display: 'flex',
        alignItems: 'flex-start'
    }
}))

const CustomCheckbox = withStyles({
    root: {
        color: '#FFF',
        '&$checked': {
            color: '#FFF',
        }
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);
