import { useNotifications } from '../../../components/Shared/NotificationSnackbar';
import {
    GetChannel, GetAnonymousUser, GetPositionContents, UpdateChannelStatus, GetCalendarEvent, StartStreamingEvent,
    GetEventStatus, StartEvent, StopEvent, RemoveContentFromGrid, ExtendContentFromGrid, ListEventSchedules, GetStreamingUserCount,
    ReportQrCodeUsed, EventSchedulesList, DeleteEventSchedule, UdpateEventSchedule, CreateEventSchedule, GetEventSchedules,
    GetEventStatusCloudfront, ListEventSchedulesCloudfront, ReportEnteredApp, GetQueueContents, RemoveFromQueue
} from '../../../service/api/video-stream';


export const VideoStreamService = () => {

    const { triggerNotification } = useNotifications();

    const getChannel = async (type, clientId) => {
        try {
            const result = await GetChannel(type, clientId);
            const errorCode = result.data?.getChannel?.errorCode
            if (errorCode === 0 | errorCode == null) {
                const channel = result.data?.getChannel?.channel
                return channel
            }
            throw new Error(errorCode)
        } catch (error) {
            switch (error) {
                case 'NetworkError':
                    //triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    return null
                default:
                    //triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    return null
            }
        }
    }

    // Get a list of channels for the feed channel position.
    const getPositionContents = async (positionList, isVideowall) => {
        try {
            const result = await GetPositionContents(positionList, isVideowall);
            const errorCode = result.data?.getPositionContents?.errorCode
            if (errorCode === 0 | errorCode == null) {
                const positions = result.data?.getPositionContents?.positionContents
                return positions
            }
            throw new Error(errorCode)
        } catch (error) {
            switch (error) {
                case 'NetworkError':
                    //triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    return null
                default:
                    //triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    return null
            }
        }
    }


    const getQueueContents = async (data) => {
        try {
            const result = await GetQueueContents(data);
            const errorCode = result.data?.getQueueContents?.errorCode
            if (errorCode === 0 | errorCode == null) {
                const queueContents = result.data?.getQueueContents?.queueContents
                return queueContents
            }
            throw new Error(errorCode)
        } catch (error) {
            switch (error) {
                case 'NetworkError':
                    return []
                default:
                    return []
            }
        }
    }



    const getAnonymousUser = async () => {
        try {
            const result = await GetAnonymousUser();
            return result
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    //triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    //triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }

    const getEventStatus = async (type) => {
        try {
            const result = await GetEventStatus(type);
            const errorCode = result.data?.getEventStatus?.errorCode
            if (errorCode === 0 | errorCode == null) {
                const status = result.data?.getEventStatus?.status
                return status
            }
            return null
        } catch (error) {
            switch (error) {
                case 'NetworkError':
                    return null
                default:
                    return null
            }
        }
    }

    const getEventSchedule = async (id) => {
        try {
            const result = await GetEventSchedules(id);
            const errorCode = result.data?.getEventSchedule?.errorCode;
            if (errorCode === 0 | errorCode == null) {
                const schedule = result.data?.getEventSchedule;
                return schedule
            }
            return []
        } catch (error) {
            switch (error) {
                case 'NetworkError':
                    return []
                default:
                    return []
            }
        }
    }

    const listEventSchedules = async (type) => {
        try {
            const result = await ListEventSchedules(type);
            const errorCode = result.data?.listEventSchedulesCached?.errorCode
            if (errorCode === 0 | errorCode == null) {
                const schedules = result.data?.listEventSchedulesCached?.items
                return schedules
            }
            return []
        } catch (error) {
            switch (error) {
                case 'NetworkError':
                    return []
                default:
                    return []
            }
        }
    }


    const eventSchedulesList = async () => {
        try {
            const result = await EventSchedulesList();
            const errorCode = result.data?.listEventSchedules?.errorCode
            if (errorCode === 0 | errorCode == null) {
                const schedules = result.data?.listEventSchedules?.items
                return schedules
            }
            return []
        } catch (error) {
            switch (error) {
                case 'NetworkError':
                    return []
                default:
                    return []
            }
        }
    }

    const deleteEventSchedule = async (id) => {
        try {
            const result = await DeleteEventSchedule(id);
            const errorCode = result.data?.deleteEventSchedule?.errorCode;
            if (errorCode === 0 | errorCode == null) {
                return true
            }
            throw new Error(errorCode)
        } catch (error) {
            switch (error) {
                case 'NetworkError':
                    //triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    return null
                default:
                    //triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    return null
            }
        }
    }

    const createEventSchedule = async (data) => {
        try {
            const result = await CreateEventSchedule(data);
            const errorCode = result.data?.createEventSchedule?.errorCode;
            if (errorCode === 0 | errorCode == null) {
                const schedule = result.data?.createEventSchedule;
                return schedule
            }
            throw new Error(errorCode)
        } catch (error) {
            switch (error) {
                case 'NetworkError':
                    //triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    return null
                default:
                    //triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    return null
            }
        }
    }

    const udpateEventSchedule = async (data) => {
        try {
            const result = await UdpateEventSchedule(data);
            const errorCode = result.data?.udpateEventSchedule?.errorCode;
            if (!errorCode) {
                return result
            }
            throw new Error(errorCode)
        } catch (error) {
            switch (error) {
                case 'NetworkError':
                    //triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    return null
                default:
                    //triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    return null
            }
        }
    }


    const getCalendarEvent = async () => {
        try {
            const result = await GetCalendarEvent();
            const errorCode = result.data?.startEvent?.errorCode
            if (errorCode === 0 | errorCode == null) {
                return result
            } else {
                return null;
            }
        } catch (error) {
            return null
        }
    }

    const getStreamingUserCount = async () => {
        try {
            const result = await GetStreamingUserCount();
            const errorCode = result.data?.getStreamingUserCount?.errorCode
            if (errorCode === 0 | errorCode == null) {
                return result.data?.getStreamingUserCount
            } else {
                return null;
            }
        } catch (error) {
            return null
        }
    }

    const startEvent = async () => {
        try {
            const result = await StartEvent();
            const errorCode = result.data?.startEvent?.errorCode
            if (errorCode === 0 | errorCode == null) {
                return result
            } else {
                return null;
            }
        } catch (error) {
            return null
        }
    }

    const stopEvent = async () => {
        try {
            const result = await StopEvent();
            return result
        } catch (error) {
            return null
        }
    }

    const reportQrCodeUsed = async (type) => {
        try {
            await ReportQrCodeUsed(type);
            return null
        } catch (error) {
            return null
        }
    }

    const reportEnteredApp = async (data, type) => {
        try {
            await ReportEnteredApp(data, type);
            return null
        } catch (error) {
            return null
        }
    }


    const startStreamingEvent = async () => {
        try {
            const result = await StartStreamingEvent();
            const errorCode = result.data?.startStreamingEvent?.errorCode
            if (errorCode === 0 | errorCode == null) {
                return result
            } else {
                return null;
            }
        } catch (error) {
            return null
        }
    }


    const extendContentFromGrid = async (position) => {
        try {
            const result = await ExtendContentFromGrid(position);
            const errorCode = result.data?.extendContentFromGrid?.errorCode;
            if (errorCode === 0 | errorCode == null) {
                const position = result.data?.extendContentFromGrid;
                return position
            }
            throw new Error(errorCode)
        } catch (error) {
            switch (error) {
                case 'NetworkError':
                    //triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    return null
                default:
                    //triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    return null
            }
        }
    }

    const removeContentFromGrid = async (position, channel) => {
        try {
            const result = await RemoveContentFromGrid(position, channel);
            const errorCode = result.data?.removeContentFromGrid?.errorCode;
            if (errorCode === 0 | errorCode == null) {
                const position = result.data?.removeContentFromGrid;
                return position
            }
            throw new Error(errorCode)
        } catch (error) {
            switch (error) {
                case 'NetworkError':
                    //triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    return null
                default:
                    //triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    return null
            }
        }
    }

    const removeFromQueue = async (queueName) => {
        try {
            const result = await RemoveFromQueue(queueName);
            const errorCode = result.data?.removeFromQueue?.errorCode;
            if (errorCode === 0 | errorCode == null) {
                const name = result.data?.removeFromQueue.name
                return name
            }
            throw new Error(errorCode)
        } catch (error) {
            switch (error) {
                case 'NetworkError':
                    //triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    return null
                default:
                    //triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    return null
            }
        }
    }


    const updateChannelStatus = async (data) => {
        try {
            const result = await UpdateChannelStatus(data);
            return result?.data.updateChannelStatus
        } catch (error) {
            const errorCode = error.code;
            switch (errorCode) {
                case 'NetworkError':
                    triggerNotification("warning", "notifications-default-NetworkError-message", "notifications-default-NetworkError-title");
                    break;
                default:
                    triggerNotification("error", "notifications-default-Error-message", "notifications-default-Error-title");
                    break;
            }
        }
    }


    const getEventStatusCloudfront = async () => {
        try {
            const result = await GetEventStatusCloudfront();
            const status = result?.data?.status
            return status;
        } catch (error) {
            switch (error) {
                case 'NetworkError':
                    return null
                default:
                    return null
            }
        }
    }


    const listEventSchedulesCloudfront = async () => {
        try {
            const result = await ListEventSchedulesCloudfront();
            const errorCode = result.data?.listEventSchedulesCached?.errorCode
            if (errorCode === 0 | errorCode == null) {
                const schedules = result.data?.items
                return schedules
            }
            return []
        } catch (error) {
            switch (error) {
                case 'NetworkError':
                    return []
                default:
                    return []
            }
        }
    }


    return {
        getChannel, getAnonymousUser, getPositionContents, updateChannelStatus, getCalendarEvent, startStreamingEvent,
        getEventStatus, startEvent, stopEvent, removeContentFromGrid, extendContentFromGrid, listEventSchedules, getStreamingUserCount,
        reportQrCodeUsed, eventSchedulesList, deleteEventSchedule, createEventSchedule, udpateEventSchedule, getEventSchedule,
        getEventStatusCloudfront, listEventSchedulesCloudfront, reportEnteredApp, getQueueContents, removeFromQueue
    }

}