import React, { useState, useEffect } from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Drawer, List, ListItem, ListItemIcon, ListItemText, ListItemAvatar, Divider, Collapse, Tooltip, Avatar, AppBar, Toolbar, IconButton, Popper, Paper, Grow, ClickAwayListener, MenuList } from '@material-ui/core';
import { PeopleRounded, RestaurantMenuRounded, StorageRounded, DonutSmallRounded, ExpandLess, ExpandMore, FiberManualRecord } from '@material-ui/icons';

import Styles from './style';

const useStyles = makeStyles(Styles);

const MenuItemNest = (props) => {

    const classes = useStyles();
    const [state, setState] = useState({
        path: props.path,
        isActive: false,
    });

    useEffect(() => {
        setState({ ...state, isActive: props.parent.location == state.path })
    }, [props.parent.location])

    const onClickPrimaryItemHandler = () => {
        props.onClick();
    }

    return (
        <ListItem
            button={!(state.isActive)}
            classes={{
                root: clsx({
                    [classes.itemNested]: true,
                    [classes.itemNestedActive]: state.isActive,
                }),
            }}
            onClick={onClickPrimaryItemHandler}
        >
            <ListItemIcon><FiberManualRecord /></ListItemIcon>
            <ListItemText primary={props.label} />
        </ListItem>
    );
}

export default MenuItemNest;