import { DateRange, Create } from '@material-ui/icons';

import ScheduleListView from './Views/List';
import ScheduleCreateView from './Views/Create';

export const Structer = {
    title: 'bo-schedule-title',
    sections: [
        {
            id: 'bo-schedule-tab-list',
            index: 0,
            label: 'bo-schedule-tab-list-label',
            icon: <DateRange />,
            path: '/schedule/list',
            component: <ScheduleListView />
        },
        {
            id: 'bo-schedule-tab-create',
            index: 1,
            label: 'bo-schedule-tab-create-label',
            icon: <Create />,
            path: '/schedule/create',
            component: <ScheduleCreateView />
        }
    ],
}