import localforage from 'localforage';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import i18next from 'i18next';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { ServiceContext } from '../services/service-context';
import { AuthContext } from '../auth/auth-context';
import { calculateDistance } from '../../service/utils';


const VideowallContext = createContext()

const VideowallProvider = ({ children }) => {

    const { VideoStream } = useContext(ServiceContext);

    const navigate = useNavigate();
    const { currentUserSession } = useContext(AuthContext);
    const [videowallLoading, setVideowallLoading] = useState(true)
    const [language, setlLanguage] = useState(null)
    const [termsAccepted, setTermsAccepted] = useState(false)
    const [showFaqs, setShowFaqs] = useState(false)
    const [showSchedule, setShowSchedule] = useState(false)
    const [showTerms, setShowTerms] = useState(false)
    const [permissionCamara, setPermissionCamara] = useState(false)
    const [permissionCamaraRequested, setPermissionCamaraRequested] = useState(false)
    const [eventActive, setEventActive] = useState(false)
    const [guid, setGuid] = useState(null)
    const [insideLocation, setInsideLocation] = useState(false)




    const onSelectLang = async (lang) => {
        await localforage.setItem('lang', lang)
        setlLanguage(lang)
        if (lang) i18next.changeLanguage(lang);

    }

    const onAcceptTerms = async (value) => {
        await localforage.setItem('terms', value)
        setTermsAccepted(value)
    }

    const onBackTerms = () => {
        setTermsAccepted(false)
        setlLanguage(null)
    }

    const requestCamPermissions = async () => {
        await navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
            var track = stream.getTracks()[0];
            track.stop();
            setPermissionCamara(true)
            setPermissionCamaraRequested(true)
        }).catch(error => {
            setPermissionCamara(false)
            setPermissionCamaraRequested(true)
        })
    }

    const showCam = async () => {
        return new Promise(async (resolve) => {
            await navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
                resolve(stream)
            }).catch(error => {
                resolve(null)
            })
        })
    }

    const checkEvent = async () => {
        const resp = await VideoStream.getCalendarEvent()
        const { events } = resp;

        const currentTimestamp = Date.now()

        let onDate = false;

        events.forEach(el => {
            if (currentTimestamp >= el.startTime && currentTimestamp <= el.endTime) {
                onDate = true
                return;
            }
        });


        if (onDate) {
            //const session = await currentUserSession()
            //let type = 'AMAZON_COGNITO_USER_POOLS'
            //if (!session) type = 'AWS_IAM'
            const status = await VideoStream.getEventStatusCloudfront()
            const isActive = status === 'ACTIVE'
            isActive ? navigate('/') : navigate('/next-event')
            setEventActive(isActive)
            setVideowallLoading(false)
        } else {
            navigate('/next-event')
            setVideowallLoading(false)
        }
        return null
    }

    const updateChannelStatus = async (channel, status) => {
        const data = {
            name: channel?.name,
            //status: 'AVAILABLE',
            status: status,
            clientId: guid
        }
        await VideoStream.updateChannelStatus(data)
    }


    const checkLocation = async (latitude, longitude, accuracy) => {
        return new Promise( async (resolve, reject) => { 
            try {
                let result = false;
                const resp = await VideoStream.getCalendarEvent()
                const { locations } = resp;
                locations.forEach(data => {
                    let dist = calculateDistance(latitude, longitude, data.lat, data.long)
                    dist = (dist - accuracy)
                    if (dist <= data.radius) {
                        result = true;
                        return
                    }
                })
                resolve(result)
            } catch (error) {
                resolve(false)
            }
        })
    }


    useEffect(() => {
        const checkSettings = async () => {
            const lang = await localforage.getItem('lang')
            const terms = await localforage.getItem('terms')
            if (!guid) {
                const _guid = uuidv4();
                setGuid(_guid)
            }
            if (lang) i18next.changeLanguage(lang);
            setlLanguage(lang)
            setTermsAccepted(!!terms)
        }

        checkSettings()
        checkEvent()
    }, [])


    useEffect(() => {
        if (termsAccepted && eventActive) {
            requestCamPermissions()
        }
    }, [termsAccepted, eventActive])




    useEffect(() => {
        let intervalId = null;
        const checkEventStatus = async () => {
            intervalId = setInterval(async () => {
                if (termsAccepted) {
                    checkEvent()
                }
            }, 5000);
        }
        checkEventStatus()
        return () => clearInterval(intervalId);
    }, [termsAccepted]);








    return (
        <VideowallContext.Provider
            value={{
                videowallLoading, setVideowallLoading,
                onAcceptTerms, termsAccepted, onBackTerms,
                showTerms, setShowTerms,
                language, onSelectLang,
                showFaqs, setShowFaqs,
                showSchedule, setShowSchedule,
                requestCamPermissions, showCam,
                checkEvent,
                permissionCamara, setPermissionCamara,
                permissionCamaraRequested,
                updateChannelStatus, guid,
                checkLocation
            }}>
            {children}
        </VideowallContext.Provider>
    );

}

function useVideowall() {
    const context = useContext(VideowallContext);
    if (!context) {
        throw new Error('useVideowall must be used within an VideowallProvider.');
    }
    return context;
}


export { VideowallProvider, useVideowall };
